<template>
	<div class="main">
		<div class="main-box">
			<div class="main-left">
				<XZLInput
					ref="inputRef"
					class="mb-3"
					style="padding: 0 10px"
					v-model="searchInput"
					@input="searchData"
					:prefix-icon="Search"
					placeholder="搜索联系人、组织"
				></XZLInput>

				<div class="tab-box">
					<div v-if="tabList.length > 1" class="tab-header">
						<el-scrollbar ref="scrollbarRef" view-class="whitespace-nowrap">
							<div v-for="(tab, index) in tabList" :key="index" @click="tabClick(index)" class="tab-header-item">
								<span class="tab-header-item-label" :class="{ active: index === activeTab }">{{ tab.label }}</span>
								<el-icon v-if="index !== tabList.length - 1" class="mx-1" color="#666">
									<ArrowRight />
								</el-icon>
							</div>
						</el-scrollbar>
					</div>

					<div class="tab-content">
						<div v-for="(tab, index) in tabList" :key="index">
							<div
								v-if="
									multiple &&
									tab.data.userList.length > 0 &&
									index === activeTab &&
									(!searchInput || (searchInput && tabList.length > 1))
								"
								style="padding: 0 10px; margin: -5px 0 5px 0"
							>
								<el-checkbox
									v-model="tab.checked"
									:indeterminate="tab.indeterminate"
									:disabled="tab.checkDisabled"
									label="全选人员"
									@change="handleCheckedAll"
								/>
							</div>
							<UserList
								v-if="index === activeTab"
								v-model="checkList"
								:loading="loading.init"
								:scrollLoading="loading.load"
								:noMore="noMore"
								:listData="tab.data"
								:disabledUser="disabledUser"
								:uniqueUser="uniqueUser"
								:multiple="multiple"
								:isCheckDepart="isCheckDepart"
								:addTab="addTab"
								@scrollLoad="getScrollData"
							/>
						</div>
					</div>
				</div>
			</div>

			<div class="main-right">
				<div class="main-header">
					<div class="flex-1">
						<span>已选：{{ checkedNumber }}</span>
					</div>
					<el-button style="margin-right: -2px" type="danger" link :disabled="!checkList.length" @click="checkList = []"
						>清空</el-button
					>
				</div>

				<div class="main-content">
					<UserList v-model="checkList" />
				</div>
			</div>
		</div>
		<div class="main-footer">
			<el-button text @click="emit('cancel')">取消</el-button>
			<el-button type="primary" @click="handleConfirm">确定</el-button>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, onMounted, reactive, computed, watch, nextTick } from 'vue'
import { debounce, concat, cloneDeep } from 'lodash-es'
import { ElScrollbar } from 'element-plus'
import { Search, ArrowRight } from '@element-plus/icons-vue'
import UserList from './userList.vue'
import XZLInput from '@/components/proxyComponents/XZLInput/index.vue'
import { TabType, ListDataType, Category, ListItem, DisabledUserType } from './types'
import { searchOrgDepartmentList, getAppOrgEmpDepartmentList } from '@/api/serveApi/basicInformation/organizationMgr'
import { getUserBaseInfoList, getUserListByDepartmentID } from '@/api/serveApi/basicInformation/personnelMgr'
interface Props {
	modelValue: ListItem[]
	isAuth?: boolean
	isIncludeDisabled?: boolean
	isCheckDepart?: boolean
	unSelectable?: number[]
	uniqueUser?: boolean
	multiple?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'conform', 'cancel'])
const checkList = ref<ListItem[]>([])

const scrollbarRef = ref<InstanceType<typeof ElScrollbar>>()
const inputRef = ref<InstanceType<typeof XZLInput>>(null)
const loading = reactive<Record<'init' | 'load', boolean>>({
	init: false,
	load: false,
})
const searchInput = ref<string>('')
const activeTab = ref<number>(0)
const tabList = ref<TabType[]>([])
const tabListCache = ref<TabType[]>(null)
const pageParams = reactive<Record<'page' | 'pageSize' | 'total', number>>({
	page: 1,
	pageSize: 20,
	total: 0,
})

// 没有更多了
const noMore = computed<boolean>(() => {
	return pageParams.total > 0 && pageParams.page > Math.ceil(pageParams.total / pageParams.pageSize)
})

// 不可选择的用户
const disabledUser = computed<DisabledUserType>(() => {
	const userData: DisabledUserType = {
		userIds: JSON.parse(JSON.stringify(props.unSelectable)),
		empIds: [],
	}
	if (props.unSelectable?.length > 0) {
		if (props.uniqueUser) {
			userData.empIds = tabList.value[activeTab.value]?.data.userList
				.filter((item) => props.unSelectable.includes(item.userId))
				.map((item) => item.empId)
		}
	}
	return userData
})

// 已选择的数量
const checkedNumber = computed(() => {
	const checkNum = {
		[Category.User]: checkList.value.filter((item) => item.type === Category.User).length,
		[Category.Depart]: checkList.value.filter((item) => item.type === Category.Depart).length,
	}
	const textList = []
	if (checkNum[Category.User] > 0 || checkList.value.length === 0) {
		textList.push(`${checkNum[Category.User]} 人`)
	}
	if (checkNum[Category.Depart] > 0) {
		textList.push(`${checkNum[Category.Depart]} 个组织`)
	}
	return textList.join('，')
})

// 监听绑定值
watch(
	() => props.modelValue,
	(newVal) => {
		checkList.value = JSON.parse(JSON.stringify(newVal ?? []))
	},
	{ deep: true, immediate: true }
)

// 监听tab数据以及已勾选数据，如果发生变化则设置全选按钮状态
watch(
	[tabList, checkList],
	() => {
		getCheckboxStatus()
	},
	{ deep: true }
)

onMounted(async () => {
	nextTick(() => {
		inputRef.value?.XZLInput?.focus()
	})
	tabList.value.push({
		label: '全部',
		data: {
			departList: [],
			userList: [],
		},
		checked: false,
		indeterminate: false,
		checkDisabled: false,
	})
	tabList.value[0].data = await getData()
})

// 获取部门及用户
const getData = async (departmentId?: string, hideKeyword?: boolean): Promise<ListDataType> => {
	loading.init = true
	const result = await Promise.all([getDepart(departmentId, hideKeyword), getUserList(departmentId, hideKeyword)])
	loading.init = false

	// 因部门详情返回的employeeNum都是0，未做统计，导致如果选择的部门是有数量的，在回显时勾选状态错误，所以需要重新手动赋值
	if (checkList.value?.length > 0) {
		checkList.value?.forEach((item) => {
			if (item.type === Category.Depart) {
				const depart = result[0]?.find((el) => el.departmentId === item.departmentId)
				if (depart) {
					item.employeeNum = depart.employeeNum
				}
			}
		})
	}

	return {
		departList: result[0] ?? [],
		userList: result[1] ?? [],
	}
}

// 获取部门列表
const getDepart = async (parentId: string = '0', hideKeyword: boolean): Promise<Array<ListItem>> => {
	const departmentName: string = hideKeyword ? '' : searchInput.value
	const params = {
		// 查询开通了人数 - 【0: 所有人员，1: 已启用人员】
		isEnable: props.isIncludeDisabled ? 0 : 1,
		// 查询开通了人数 - 【0: 默认所有人员, 1: 查询开通账号的人数】
		isLinkStatus: 1,
		// 是否需要部门的人员数量 - 【0: 默认不返回, 1: 返回人员数量】
		isNeedEmpNum: 1,
		// 是否需要数据权限 -  【0: 默认返回数据权限的数据, 1: 不做数据权限过滤】
		isDataAuth: props.isAuth ? 0 : 1,
		// 部门名称
		departmentName,
		// 父级id
		parentId: '',
		departmentId: '',
	}

	// 有搜索关键字时调用搜索接口
	if (searchInput.value) {
		// 父级id
		params.parentId = departmentName ? null : parentId
		const res = await searchOrgDepartmentList(params)
		if (res && res.code === 0) {
			return formatTreeData(res.data)
		}
		return []
	} else {
		// 父级id
		params.departmentId = parentId === '0' ? null : parentId
		const res = await getAppOrgEmpDepartmentList(params)
		return formatTreeData(res?.data ?? [])
	}
}

// 获取用户列表
const getUserList = async (departmentId: string = '0', hideKeyword: boolean): Promise<Array<ListItem>> => {
	const name: string = hideKeyword ? '' : searchInput.value
	const params = {
		// 部门id
		departmentId: name ? null : departmentId,
		// 人员名称
		name,
		// 是否只包含当前部门的人员 0-默认包含子部门 1-只查询当前部门
		isOnlyCurDept: 1,
		// 状态 0所有 1启用 2停用
		status: props.isIncludeDisabled ? 0 : 1,
		// 是否需要数据权限 -  【0: 默认返回数据权限的数据, 1: 不做数据权限过滤】
		isDataAuth: props.isAuth ? 0 : 1,
	}
	let res: ResultType = null
	// 页面初始化以及点击部门时，获取该部门人员全量数据
	if (hideKeyword || (departmentId === '0' && !searchInput.value)) {
		res = await getUserListByDepartmentID(params)
	} else {
		// 搜索时分页获取人员
		Object.assign(params, { page: pageParams.page, pageSize: pageParams.pageSize })
		res = await getUserBaseInfoList(params)
	}
	if (res && res.code === 0) {
		pageParams.total = res.data.total ?? 0
		const data = (Array.isArray(res.data) ? res.data : res.data?.list) ?? []
		return formatEmpData(data)
	}
	return []
}

// 搜索
const searchData = debounce(async () => {
	Object.assign(pageParams, { page: 1, total: 0 })
	if (searchInput.value) {
		// 缓存之前的数据
		if (!tabListCache.value) {
			tabListCache.value = cloneDeep(tabList.value)
		}

		// 清空
		activeTab.value = 0
		tabList.value = []

		// 重新获取数据
		tabList.value.push({
			label: '全部',
			data: {
				departList: [],
				userList: [],
			},
			checked: false,
			indeterminate: false,
			checkDisabled: false,
		})
		tabList.value[0].data = await getData()
	} else {
		if (!tabListCache.value) return

		// 清空
		activeTab.value = 0
		tabList.value = []

		nextTick(() => {
			activeTab.value = tabListCache.value.length - 1
			tabList.value = cloneDeep(tabListCache.value)
			tabListCache.value = null
		})
	}
}, 300)

// 滚动加载人员数据
const getScrollData = async () => {
	pageParams.page++
	if (pageParams.page > Math.ceil(pageParams.total / pageParams.pageSize) || loading.load) {
		return
	}
	loading.load = true
	const res = await getUserBaseInfoList({
		departmentId: null,
		isDataAuth: props.isAuth ? 0 : 1,
		isOnlyCurDept: 1,
		name: searchInput.value,
		page: pageParams.page,
		pageSize: pageParams.pageSize,
		status: props.isIncludeDisabled ? 0 : 1,
	})
	loading.load = false
	if (res && res.code === 0) {
		const data = formatEmpData(res.data?.list)
		tabList.value[activeTab.value].data.userList = concat(tabList.value[activeTab.value].data.userList, data)
	}
}

// 格式化部门数据
const formatTreeData = (treeData) => {
	if (!treeData) return []

	// 过滤已停用的部门
	const data = treeData.filter((item) => {
		if (props.isIncludeDisabled) {
			return true
		}
		return item.status === 1
	})

	return data.map((item) => {
		return {
			type: Category.Depart,
			departmentId: item.departmentId,
			departmentName: item.name,
			employeeNum: item.employeeNum,
			status: item.status,
		}
	})
}

// 格式化人员数据
const formatEmpData = (data) => {
	if (!data) return []
	return data.map((item) => {
		return {
			type: Category.User,
			empId: item.empId,
			userId: item.userId,
			userName: item.name,
			departmentId: item.departmentId,
			departmentName: item.departmentName,
			departmentNameList: item.departmentNameList,
			jobPositionId: item.jobPositionId,
			jobPositionName: item.jobPositionName,
			headerImg: item.headerImg,
			status: item.status,
		}
	})
}

// 点击tab
const tabClick = (index) => {
	activeTab.value = index
	tabList.value = tabList.value.slice(0, index + 1)
}

// 添加tab项
const addTab = async (depart: ListItem) => {
	tabList.value.push({
		label: depart.departmentName,
		data: {
			departList: [],
			userList: [],
		},
		checked: false,
		indeterminate: false,
		checkDisabled: false,
	})
	activeTab.value = tabList.value.length - 1
	tabList.value[activeTab.value].data = await getData(depart.departmentId, true)

	// 出现横向滚动条立即滚动到最右
	if (scrollbarRef.value?.wrapRef.clientWidth < scrollbarRef.value?.wrapRef.scrollWidth) {
		scrollbarRef.value?.setScrollLeft(scrollbarRef.value.wrapRef.scrollWidth)
	}
}

// 全选
const handleCheckedAll = (value) => {
	if (value) {
		const list = tabList.value[activeTab.value].data.userList?.filter((item) => {
			if (props.uniqueUser) {
				return !disabledUser.value.empIds.includes(item.empId)
			}
			return !disabledUser.value.userIds.includes(item.userId)
		})
		checkList.value.push(...list)
	} else {
		const userIds: number[] = tabList.value[activeTab.value].data.userList.map((item) => item.userId)
		checkList.value = checkList.value.filter((item) => !userIds.includes(item.userId))
	}
}

// 获取全选按钮状态
const getCheckboxStatus = () => {
	if (typeof activeTab.value !== 'number' || tabList.value.length === 0) return

	const tabData = tabList.value[activeTab.value]
	const userIds: number[] = checkList.value.map((item) => item.userId)
	const checkedLength: number = tabData.data.userList.filter((item) => userIds.includes(item.userId)).length

	switch (checkedLength) {
		case 0:
			tabData.checked = false
			tabData.indeterminate = false
			break
		case tabData.data.userList.length:
			tabData.checked = true
			tabData.indeterminate = false
			break
		default:
			tabData.checked = true
			tabData.indeterminate = true
	}

	// 可勾选的用户数量
	const isCheckableLength: number = tabData.data.userList.filter((item) => {
		if (props.uniqueUser) {
			return !disabledUser.value.empIds.includes(item.empId)
		}
		return !disabledUser.value.userIds.includes(item.userId)
	}).length

	if (isCheckableLength === 0) {
		tabData.checkDisabled = true
	} else {
		tabData.checkDisabled = false
	}
}

// 确认
const handleConfirm = () => {
	const value = JSON.parse(JSON.stringify(checkList.value))
	emit('update:modelValue', value)
	emit('conform', value)
}
</script>

<style lang="scss" scoped>
.main {
	width: 800px;
	margin: auto;

	.main-box {
		position: relative;
		display: flex;
		border: 1px solid theme('colors.line.1');
		border-radius: 12px;

		.main-left,
		.main-right {
			width: 50%;
			padding: 20px 10px 10px 10px;
		}

		.main-left {
			border-right: 1px solid theme('colors.line.1');

			.tab-box {
				.tab-header {
					height: 32px;
					padding: 0 10px;
					margin-top: 10px;

					.tab-header-item {
						display: inline-flex;
						align-items: center;

						.tab-header-item-label {
							cursor: pointer;

							&.active,
							&:hover {
								color: var(--el-color-primary);
							}
						}
					}
				}
			}
		}

		.main-right {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			display: flex;
			flex-direction: column;

			.main-header {
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: 32px;
				padding: 0 10px;
			}

			.main-content {
				flex: 1;
				overflow: auto;
			}
		}
	}

	.main-footer {
		margin-top: 20px;
		text-align: right;
	}
}
</style>

