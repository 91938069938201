<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'FormProTable',
})
</script>
<template>
	<div class="table-box">
		<!-- QueryForm -->
		<div v-if="$slots.queryForm" class="table-header">
			<slot name="queryForm"></slot>
		</div>

		<!-- Table Content -->
		<div class="table-content">
			<XZLTable
				ref="tableRef"
				v-loading="tableLoading"
				height="100%"
				:border="true"
				:rowKey="rowKey"
				:data="tableData"
				:columns="columns"
				@selection-change="handleSelectionChange"
				@current-change="rowChange"
				v-bind="tableAttrs"
			>
				<template v-for="name in Object.keys(tableSlots)" #[name]="scope">
					<slot :name="name" v-bind="scope || {}"></slot>
				</template>
			</XZLTable>
		</div>

		<div
			class="flex items-center text-gray-500"
			:class="[
				showPagination && showCheckNumber && (checkNumber > 0 || selectionList.length > 0)
					? 'justify-between'
					: 'justify-end',
			]"
		>
			<span v-if="showCheckNumber && (checkNumber > 0 || selectionList.length > 0)"
				>已选{{ checkNumber || selectionList.length }}条</span
			>

			<!-- Pagination -->
			<xzl-pagination
				v-if="showPagination"
				class="mt-0"
				:current-page="pageable.page"
				:page-size="pageable.pageSize"
				:pageSizes="pageSizes"
				:total="total"
				:size-change="sizeChange"
				:current-change="currentChange"
			/>
		</div>
	</div>
</template>

<script setup lang="ts" name="FormProTable">
import { ref, computed, useAttrs, useSlots } from 'vue'
import { omit, slice } from 'lodash-es'

import XZLTable from '@/components/proxyComponents/XZLTable/index.vue'
import { TablePropType } from '@/components/proxyComponents/XZLTable/types'

import { useTable } from '../common/hooks/useTable/index'
import { UseTableOptions } from '../common/hooks/useTable/types'

interface Props extends Pick<TablePropType, 'rowKey' | 'columns' | 'data'> {
	// 表格接口配置
	request?: {
		api: (data: any) => any
		params?: UseTableOptions
	}

	// api返回的数据中，属于表格data的键名(key)，默认为list。如果api返回的是数组，则此属性应为空
	dataKey?: string

	// 是否需要分页，默认true
	showPagination?: boolean

	// 分页
	pageSize?: number

	// 分页参数
	pageSizes?: number[]

	// 是否显示勾选数量(目前只有添加产品会显示 @/components/addProduct)，默认false
	showCheckNumber?: boolean

	// 已选择的数量，非必传。如果开启分页勾选，则需要传入此参数，因为表格中实时勾选的数量显示不对
	checkNumber?: number
}

defineOptions({
	inheritAttrs: false,
})

const props = withDefaults(defineProps<Props>(), {
	rowKey: 'ID',
	dataKey: 'list',
	showPagination: true,
	pageSizes: () => [20, 50, 100],
})

const attrs = useAttrs()
const slots = useSlots()

// 排除其他插槽，只需要表格的插槽
const tableSlots = omit(slots, 'queryForm')
// 排除data（需要手动处理data）
const tableAttrs = omit(attrs, 'data')

const tableRef = ref<InstanceType<typeof XZLTable>>()

const {
	tableLoading,
	pageable,
	tableState,
	selectionList,
	getTableList,
	handlePageChange,
	handleSizeChange,
	handleSelectionChange,
	handleCurrentChange,
} = useTable(props.request?.api, {
	immediate: props.request ? true : false,
	tableType: 'formProTable',
	...props.request?.params,
	pageSize: props.pageSize,
})

// 表格数据
const tableData = computed(() => {
	const data = props.data
	// 如果data从外部传入，则前端手动分页
	if (data?.length > 0) {
		// 不需要分页
		if (!props.showPagination) {
			return data
		}

		// 手动分页
		const page = pageable.value.page as number
		const pageSize = pageable.value.pageSize as number
		return slice(data, (page - 1) * pageSize, page * pageSize)
	}

	// 使用api接口数据
	return props.dataKey ? tableState.value?.[props.dataKey] : tableState.value
})

// 总数
const total = computed<number>(() => {
	const data = props.data
	return tableState.value?.total ?? data?.length
})

// 分页
const currentChange = async (val: number) => {
	await handlePageChange(val)
	tableRef.value!.XZLTable.setScrollTop(0)
}

// 分页
const sizeChange = async (val: number) => {
	await handleSizeChange(val)
	tableRef.value!.XZLTable.setScrollTop(0)
}

// 单选
const rowChange = (row: any, oldRow: any) => {
	// 没有单选图标，则不选中当前行
	const typeColumn = props.columns.find((item) => item.type === 'radio')
	if (!typeColumn) {
		return
	}
	// 选中行单选被禁用
	if (typeof typeColumn?.selectable === 'function') {
		const selectable = typeColumn.selectable(row, null)
		if (!selectable && !!oldRow) {
			return
		}
	}
	handleCurrentChange(row)
}

const exposeMap = {
	tableState,
	pageable,
	element: tableRef,
	getTableList,
	selectionList,
}

defineExpose(exposeMap)
</script>

<style lang="scss" scoped>
.table-box {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	gap: 12px;

	.table-header {
		position: relative;
	}

	.table-content {
		flex: 1;
		height: 0;
	}
}
</style>

