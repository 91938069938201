<template>
	<div class="el-input" :class="{ 'is-disabled': disabled }">
		<div class="el-input__wrapper w-full" :class="{ 'is-focus': active }">
			<el-scrollbar v-if="userList.length > 0" max-height="90" class="el-input__inner" style="height: auto">
				<!-- 将选中的标签合并 -->
				<div v-if="collapseTags" class="inline-flex w-full">
					<template v-for="(tag, index) in userList" :key="tag.type === Category.User ? tag.userId : tag.departmentId">
						<el-tag
							v-if="index === 0"
							class="mr-1 overflow-hidden"
							type="info"
							:closable="!disabled"
							@close="emit('remove', index)"
						>
							<template v-if="tag.type === Category.Depart">
								<el-tooltip effect="dark" v-if="tag.departmentName" :content="tag.departmentName" placement="bottom">
									{{ tag.departmentName }}
								</el-tooltip>
							</template>
							<template v-if="tag.type === Category.User">
								<el-tooltip effect="dark" v-if="tag.userName" :content="tag.userName" placement="bottom">
									{{ tag.userName }}
								</el-tooltip>
							</template>
						</el-tag>
					</template>
					<el-popover
						v-if="userList.length > 1"
						placement="bottom"
						width="auto"
						trigger="hover"
						:popper-style="{ minWidth: 'unset' }"
					>
						<template #reference>
							<el-tag v-if="userList.length > 1" type="info">+{{ userList?.length - 1 }}</el-tag>
						</template>
						<div style="max-width: 300px; max-height: 280px; overflow: auto">
							<template v-for="(tag, index) in userList" :key="tag.userId">
								<el-tag
									v-if="index !== 0"
									class="mr-1 mb-1"
									type="info"
									:closable="!disabled"
									@close="emit('remove', index)"
								>
									{{ tag.type === Category.User ? tag.userName : tag.departmentName }}
								</el-tag>
							</template>
						</div>
					</el-popover>
				</div>

				<!-- 不合并标签 -->
				<template v-else>
					<el-tag
						v-for="(tag, index) in userList"
						:key="index"
						class="mr-1 max-w-full"
						type="info"
						:closable="!disabled"
						@close="emit('remove', index)"
					>
						{{ tag.type === Category.User ? tag.userName : tag.departmentName }}
					</el-tag>
				</template>
			</el-scrollbar>
			<div v-else class="el-input__inner truncate" style="height: 30px">
				<span style="color: var(--el-input-placeholder-color)">{{ placeholder }}</span>
			</div>

			<span v-if="!disabled && userList.length > 0" class="el-input__suffix">
				<span class="el-input__suffix-inner">
					<el-icon class="el-input__clear" @click.stop="emit('removeAll')"><CircleClose /></el-icon>
				</span>
			</span>
		</div>
	</div>
</template>

<script setup lang="ts">
import { CircleClose } from '@element-plus/icons-vue'
import { ListItem, Category } from './types'

interface Props {
	userList: ListItem[]
	active?: boolean
	collapseTags?: boolean
	disabled?: boolean
	placeholder?: string
}

defineProps<Props>()
const emit = defineEmits(['remove', 'removeAll'])
</script>

<style lang="scss" scoped>
.el-input__wrapper {
	.el-tag {
		margin-top: -4px;
		border-width: 0;
	}
}
</style>

