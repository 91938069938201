import { VNode, reactive } from 'vue'
import { ElMessage, type ButtonType } from 'element-plus'
import { handlePermissions } from '@/hooks/userPagePermissions'
import { useWindowSize, watchDebounced } from '@vueuse/core'
import {
	ApprovalStatusMap,
	AcctiveStatusMap,
	expenseActivityStatusMap,
	RefridgeratorPlanStatusMap,
	channelInventoryRecordStatusMap,
	ActivitySystemPresetMap,
	budgetaryStatusMap,
	applicationOddStatusMap,
	cancellationStatusMap,
	policyStatusMap,
	policyUploadStatusMap,
	ContractApprovalStatusMap,
	ActivityOverApplicationStatusMap,
} from '@/config/Constantconfig'
import { DrawerType } from '../usePageDrawer/types'

/**
 * @description 统一弹窗宽度百分比
 * @param PERCENTAGE20  20%
 * @param PERCENTAGE30  30%
 * @param PERCENTAGE40  40%
 * @param PERCENTAGE60  60%
 * @param PERCENTAGE100  100%
 */
export enum ModalWidthType {
	PERCENTAGE10 = '10%',
	PERCENTAGE20 = '20%',
	PERCENTAGE30 = '30%',
	PERCENTAGE40 = '40%',
	PERCENTAGE60 = '60%',
	PERCENTAGE85 = '85%',
	PERCENTAGE100 = '100%',
}

/** 全局按钮枚举类型集合 */
export enum OpeationBtnType {
	/** ADD  新增*/
	ADD = 'ADD',
	/**  EDIT  编辑*/
	EDIT = 'EDIT',
	/** DELETE  删除*/
	DELETE = 'DELETE',
	/** VIEW  查看*/
	VIEW = 'VIEW',
	/** SUBMITTERMINALORDER  提交订单*/
	SUBMITTERMINALORDER = 'SUBMITTERMINALORDER',
	/** ENABLE  启用*/
	ENABLE = 'ENABLE',
	/** DISABLE  停用*/
	DISABLE = 'DISABLE',
	/** SETCONTACT  变更负责人*/
	SETCONTACT = 'SETCONTACT',
	/** SETAREA  转化销售区域*/
	SETAREA = 'SETAREA',
	/** CREATEACCOUNT 生成账号*/
	CREATEACCOUNT = 'CREATEACCOUNT',
	/** SETPOSITION 设置主职位*/
	SETPOSITION = 'SETPOSITION',
	/** APPLYPOSITION 应用职位*/
	APPLYPOSITION = 'APPLYPOSITION',
	/** SETCHARGEDEPT 设置负责组织*/
	SETCHARGEDEPT = 'SETCHARGEDEPT',
	/** SETLEADER 设置所属上级*/
	SETLEADER = 'SETLEADER',
	/** HANDOVER 交接*/
	HANDOVER = 'HANDOVER',
	/** RESIGN 离职*/
	RESIGN = 'RESIGN',
	/** SETAPPLYDEPT 设置应用组织*/
	SETAPPLYDEPT = 'SETAPPLYDEPT',
	/** EXPORT  导出*/
	EXPORT = 'EXPORT',
	/** IMPORT  导入*/
	IMPORT = 'IMPORT',
	/** IMPORTUPDATE  导入更新 */
	IMPORTUPDATE = 'IMPORTUPDATE',
	/** CUSTOMIZECANCELLATIONADD 新增自定义核销单*/

	CUSTOMIZECANCELLATIONADD = 'CUSTOMIZECANCELLATIONADD',
	/** STARTCANCELLATION 发起核销*/
	STARTCANCELLATION = 'STARTCANCELLATION',
	/** STOPCANCELLATION 终止核销*/
	STOPCANCELLATION = 'STOPCANCELLATION',
	/** IMPORTBASICINFO  导入基础信息*/
	IMPORTBASICINFO = 'IMPORTBASICINFO',
	/** IMPORTPRICESETTING  导入价格设置*/
	IMPORTPRICESETTING = 'IMPORTPRICESETTING',
	/** UPVALIDITYTDATE  更改有效期*/
	UPVALIDITYTDATE = 'UPVALIDITYTDATE',
	BUDGETARYEXPORT = 'BUDGETARYEXPORT',
	SALEDELIVERYEXPORT = 'SALEDELIVERYEXPORT',
	BUDGETARYIMPORT = 'BUDGETARYIMPORT',
	SALEDELIVERYIMPORT = 'SALEDELIVERYIMPORT',
	EXPORTCANCELLATIONRECORD = 'EXPORTCANCELLATIONRECORD',
	/** EXPORTFACTORYCANCELLATIONSUMMARY 导出工厂核销汇总*/
	EXPORTFACTORYCANCELLATIONSUMMARY = 'EXPORTFACTORYCANCELLATIONSUMMARY',
	/** EXPORTINVENTORYRECORDS 导出上报记录*/
	EXPORTINVENTORYRECORDS = 'EXPORTINVENTORYRECORDS',
	/** EXPORTINVENTORYRECORDDETAILS 导出上报记录明细*/
	EXPORTINVENTORYRECORDDETAILS = 'EXPORTINVENTORYRECORDDETAILS',
	/** RESETPASSWORD 重置密码*/
	RESETPASSWORD = 'RESETPASSWORD',
	/** ADJUST 调整*/
	ADJUST = 'ADJUST',
	/** CANCEL 作废*/
	CANCEL = 'CANCEL',
	/** ASSIGNCUSTOMERS 分配客户*/
	ASSIGNCUSTOMERS = 'ASSIGNCUSTOMERS',
	/** CLEARDEVICEBIND 强制清除绑定*/
	CLEARDEVICEBIND = 'CLEARDEVICEBIND',
	/** ENABLEDEVICEBIND 开启设备绑定*/
	ENABLEDEVICEBIND = 'ENABLEDEVICEBIND',
	/** DISABLEDDEVICEBIND  关闭设备绑定*/
	DISABLEDDEVICEBIND = 'DISABLEDDEVICEBIND',
	/** TAGDISABLE  停用标签*/
	TAGDISABLE = 'TAGDISABLE',
	/** TAGADD  新增标签*/
	TAGADD = 'TAGADD',
	/** TAGENABLE 启用标签*/
	TAGENABLE = 'TAGENABLE',
	/** TAGEDIT  编辑标签*/
	TAGEDIT = 'TAGEDIT',
	/** TAGDELETE  删除标签*/
	TAGDELETE = 'TAGDELETE',
	/** SETTAG  设置标签*/
	SETTAG = 'SETTAG',
	/** SETDISTRIBUTOR  设置经销商*/
	SETDISTRIBUTOR = 'SETDISTRIBUTOR',
	/** SUBMIT 提交*/
	SUBMIT = 'SUBMIT',
	/** SUBMITAPPROVAL 提交审批*/
	SUBMITAPPROVAL = 'SUBMITAPPROVAL',
	/** INTOINFOCONTRACT 生成电子合同*/
	INTOINFOCONTRACT = 'INTOINFOCONTRACT',
	/** COPY 复制*/
	COPY = 'COPY',
	/** COPYANDNEW 复制并新建*/
	COPYANDNEW = 'COPYANDNEW',
	/** APPROVAL 审核*/
	APPROVAL = 'APPROVAL',
	/** CLOSE  关闭*/
	CLOSE = 'CLOSE',
	/** VIEWEMPLOYEE  查看成员*/
	VIEWEMPLOYEE = 'VIEWEMPLOYEE',
	/** INITIATE  发起*/
	INITIATE = 'INITIATE',
	/** DEVICELOGINLOG   登录历史记录*/
	DEVICELOGINLOG = 'DEVICELOGINLOG',
	/** EXPORTDETAILS   导出订单明细*/
	EXPORTDETAILS = 'EXPORTDETAILS',
	/** SHOWSHIP   查看发货记录*/
	SHOWSHIP = 'SHOWSHIP',
	/** IMPORTSHIP   导入发货*/
	IMPORTSHIP = 'IMPORTSHIP',
	/** EXPORTSHIP   导出发货明细*/
	EXPORTSHIP = 'EXPORTSHIP',
	/** IMPORTBILLINFO   导入开票信息*/
	IMPORTBILLINFO = 'IMPORTBILLINFO',
	/** IMPORTBILLHEAD   导入开票抬头*/
	IMPORTBILLHEAD = 'IMPORTBILLHEAD',
	/** MODIFY   修改*/
	MODIFY = 'MODIFY',
	/** PUBLISHPLAN   发布计划*/
	PUBLISHPLAN = 'PUBLISHPLAN',
	/** EDITPLAN   编辑计划*/
	EDITPLAN = 'EDITPLAN',
	/** ADDREPORTER   添加提报人*/
	ADDREPORTER = 'ADDREPORTER',
	/** ADDTEMPORARYUPLOADER   添加临时上传人员*/
	ADDTEMPORARYUPLOADER = 'ADDTEMPORARYUPLOADER',
	/** ADDVIEWDETAILSUSER   添加查看明细人员*/
	ADDVIEWDETAILSUSER = 'ADDVIEWDETAILSUSER',
	/** UPDATESCHEDULE 更新明细表*/
	UPDATESCHEDULE = 'UPDATESCHEDULE',
	/** CONFIRM 确认*/
	CONFIRM = 'CONFIRM',
	/** EXPORTDEMANDDETAILS   导出需求明细*/
	EXPORTDEMANDDETAILS = 'EXPORTDEMANDDETAILS',
	/** EXPORTFACTORYDEMANDSUMMARY   导出工厂需求汇总*/
	EXPORTFACTORYDEMANDSUMMARY = 'EXPORTFACTORYDEMANDSUMMARY',
	/** READ   标位为已读*/
	READ = 'READ',
	/** MUITILEADING  多模板导入*/
	MUITILEADING = 'MUITILEADING',
	/** INVENTORYADD 渠道调库申请*/
	INVENTORYADD = 'INVENTORYADD',
	/** IMPORTAPPROVEL 批量导入审批*/
	IMPORTAPPROVEL = 'IMPORTAPPROVEL',
	/** DIMENSIONVALUE // 维值*/
	DIMENSIONVALUE = 'DIMENSIONVALUE',
	// TODO 暂未使用
	SUBMITAPPROVE = 'SUBMITAPPROVE',
	/** EXPORTALLOCATERECORDS 调库记录*/
	EXPORTALLOCATERECORDS = 'EXPORTALLOCATERECORDS',
	/** EXPORTALLOCATERETAILS 调库明细*/
	EXPORTALLOCATERETAILS = 'EXPORTALLOCATERETAILS',
	/** REGULARINVENTORY 常规库存上报*/
	REGULARINVENTORY = 'REGULARINVENTORY',
	/** INVENTORY 库存调拨*/
	INVENTORY = 'INVENTORY',
	/** INVENTORYCOUNT  库存上报*/
	INVENTORYCOUNT = 'INVENTORYCOUNT',
	/** IMPORTMODIWARPER  导入修改保修期*/
	IMPORTMODIWARPER = 'IMPORTMODIWARPER',
	/** IMPORTBATCHADJUST  批量调整*/
	IMPORTBATCHADJUST = 'IMPORTBATCHADJUST',
	/** REFRINODELIVER  冰箱设置未投放*/
	REFRINODELIVER = 'REFRINODELIVER',
	/** ADDINVENTORY  新增上报*/
	ADDINVENTORY = 'ADDINVENTORY',
	/** POLICYENTORY  政策上报*/
	POLICYENTORY = 'POLICYENTORY',
	/** CONTINUE 续签*/
	CONTINUE = 'CONTINUE',
	/** BULKTRANSFERFREEZERS  冰箱批量转移*/
	BULKTRANSFERFREEZERS = 'BULKTRANSFERFREEZERS',
	/** IMPORTREPAIRCOSTS 导入维修费用*/
	IMPORTREPAIRCOSTS = 'IMPORTREPAIRCOSTS',
	/** SETREPAIRCOSTS 设置维修费用*/
	SETREPAIRCOSTS = 'SETREPAIRCOSTS',
	/** ASSIGNORDERS 分配订单*/
	ASSIGNORDERS = 'ASSIGNORDERS',
	/** TEMPORARYPASSWORD  获取动态密码*/
	TEMPORARYPASSWORD = 'TEMPORARYPASSWORD',
	/** DETAIL 详情*/
	DETAIL = 'DETAIL',
	/** ACTIVITYOVERAPPLICATION  提交结案*/
	ACTIVITYOVERAPPLICATION = 'ACTIVITYOVERAPPLICATION',
	/** CHECKREVIEW  检核*/
	CHECKREVIEW = 'CHECKREVIEW',
	/** TRANSFERROUTE  拜访-路线管理-路线汇总-转移路线*/
	TRANSFERROUTE = 'TRANSFERROUTE',
	/** LOOK 拜访-路线管理-路线汇总-查看*/
	LOOK = 'LOOK',
	/** SIGNATURE 签章*/
	SIGNATURE = 'SIGNATURE',
	/** DOWNLOAD 下载*/
	DOWNLOAD = 'DOWNLOAD',
	/** BUDGETARYADJUST 调整*/
	BUDGETARYADJUST = 'BUDGETARYADJUST', //预算调整
	/** BUDGETARYORGANIZATION 编制*/
	BUDGETARYORGANIZATION = 'BUDGETARYORGANIZATION', // 预算编制
	/** PUBLIC 发布*/
	PUBLIC = 'PUBLIC',
	/** COPYANNOUNCEMENT 复制公告*/
	COPYANNOUNCEMENT = 'COPYANNOUNCEMENT',
	/** TOPPING 置顶*/
	TOPPING = 'TOPPING',
	/** CANCELTOPPING 取消置顶*/
	CANCELTOPPING = 'CANCELTOPPING',
	/** REVOKE 撤回*/
	REVOKE = 'REVOKE',
	/** CONFIRMVERIFICATION  修改核销金额*/
	CONFIRMVERIFICATION = 'CONFIRMVERIFICATION', // 确认核销金额
	/** REPAYMENT 确认还库*/
	REPAYMENT = 'REPAYMENT',
	/** BATCHTRANSFER 批量转批*/
	BATCHTRANSFER = 'BATCHTRANSFER',
	/** BATCHAPPROVAL 批量审批*/
	BATCHAPPROVAL = 'BATCHAPPROVAL',
	IMPORTAPPROVAL = 'IMPORTAPPROVAL', // 导入审批
	IMPORTINVOICEHEADER = 'IMPORTINVOICEHEADER',
	VERIFICATIONNUMBEDETAILS = 'VERIFICATIONNUMBEDETAILS', // 导入修改开票抬头
	SETVISITEDUNUSUAL = 'SETVISITEDUNUSUAL', // 异常签退
	/** BATCHAPPROVAL 批量更新*/
	BATCHUPDATE = 'BATCHUPDATE',
	RECALCULATEREVENUE = 'RECALCULATEREVENUE', // 预算营业额重算
	CREATETERMINALORDER = 'CREATETERMINALORDER', // 新建订单
	DISCARDTERMINALORDER = 'DISCARDTERMINALORDER', // 作废订单
}
export interface BtnItem {
	type: OpeationBtnType
	label: VNode | string
	icon?: string
	buttonType?: ButtonType
	width?: string
	isSelect?: boolean
	value?: number
	weight?: number
	selectionArr?: []
	hasPermision?: boolean
}
export interface DialogInfo {
	title: string
	visible: boolean
	label?: VNode | string
	type?: OpeationBtnType
	content?: string
	width?: string
	handleOk?: (params: unknown) => void
}

export interface ApprovalStatusValidOptions {
	valid: number | number[] //审批状态集合
	approvalStatusMap?: typeof ApprovalStatusMap //审批状态集合 TODO后续废除
	key?: string // 不同状态的 标识
	type?: DrawerType
	statusName?: string
	customStatusValid?: number | number[] //业务状态集合
	// 是否合并审批流程与业务流程
	isMerge?: boolean
	isRelativeValidLabel?: boolean // 是否返回状态取反值的 名称 all:[1,2,3] valid:[2,3] =>[1]
}

interface ApprovalListStatus {
	approvalStatus: number
	[key: string]: any
}

/**权重第一梯度按钮 */
const weightLayer1 = [
	{ type: OpeationBtnType.ADD, label: '新增', icon: 'icon-add', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.TAGADD, label: '新增', icon: 'icon-add', width: ModalWidthType.PERCENTAGE60 },
]
/**权重第二梯度按钮 */
const weightLayer2 = [
	{
		type: OpeationBtnType.PUBLISHPLAN,
		label: '发布计划',
		icon: 'icon-release',
		width: ModalWidthType.PERCENTAGE60,
	},
	{ type: OpeationBtnType.ADDINVENTORY, label: '新增上报', icon: 'icon-add', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.INITIATE, label: '发起', icon: 'icon-initiate', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.SHOWSHIP, label: '查看发货记录', icon: 'icon-view', width: ModalWidthType.PERCENTAGE60 },
	{
		type: OpeationBtnType.IMPORTBILLINFO,
		label: '导入开票信息',
		icon: 'icon-import-basic-information',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.IMPORTBATCHADJUST,
		label: '批量调整',
		icon: 'icon-batch-adjustment',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.RESETPASSWORD,
		label: '重置密码',
		icon: 'icon-reset-password',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.CLEARDEVICEBIND,
		label: '强制清除绑定',
		icon: 'icon-clear-device-binding',
		width: ModalWidthType.PERCENTAGE20,
	},

	{
		type: OpeationBtnType.CUSTOMIZECANCELLATIONADD,
		label: '新增自定义核销单',
		icon: 'icon-add',
		width: ModalWidthType.PERCENTAGE60,
	},
	{ type: OpeationBtnType.INVENTORYADD, label: '渠道调库申请', icon: 'icon-add', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.ADJUST, label: '调整', icon: 'icon-adjust', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.CHECKREVIEW, label: '检核', icon: 'icon-check', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.POLICYENTORY, label: '上报', icon: 'icon-escalation', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.APPROVAL, label: '审核', width: ModalWidthType.PERCENTAGE20 },
	{ type: OpeationBtnType.READ, label: '标记为已读', icon: 'icon-mark-as-read', width: ModalWidthType.PERCENTAGE20 },
	{
		type: OpeationBtnType.REGULARINVENTORY,
		label: '常规库存上报',
		icon: 'ele-Plus',
		width: ModalWidthType.PERCENTAGE60,
	},
	{ type: OpeationBtnType.INVENTORYCOUNT, label: '库存上报', icon: 'ele-Plus', width: ModalWidthType.PERCENTAGE60 },
	{
		type: OpeationBtnType.TRANSFERROUTE,
		label: '转移路线',
		icon: 'icon-transfer-route',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.SETVISITEDUNUSUAL,
		label: '异常签退',
		icon: 'icon-sign-out',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.CREATETERMINALORDER,
		label: '订单上报',
		icon: 'icon-add',
		width: ModalWidthType.PERCENTAGE60,
	},
]

/**权重第三梯度按钮 */
const weightLayer3 = [
	{ type: OpeationBtnType.EDIT, label: '编辑', icon: 'icon-edit', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.TAGEDIT, label: '编辑', icon: 'icon-edit', width: ModalWidthType.PERCENTAGE60 },
	{
		type: OpeationBtnType.EDITPLAN,
		label: '编辑计划',
		icon: 'icon-edit',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.COPYANDNEW,
		label: '复制并新建',
		icon: 'icon-copy-and-create-a-new-one',
		width: ModalWidthType.PERCENTAGE20,
	},
	{ type: OpeationBtnType.PUBLIC, label: '发布', icon: 'icon-release', width: ModalWidthType.PERCENTAGE20 },
	{ type: OpeationBtnType.COPYANNOUNCEMENT, label: '复制', icon: 'icon-copy', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.COPY, label: '复制', icon: 'icon-copy', width: ModalWidthType.PERCENTAGE20 },
	{
		type: OpeationBtnType.ASSIGNCUSTOMERS,
		label: '分配客户',
		icon: 'icon-assign-customers',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.REPAYMENT,
		label: '确认还库',
		icon: 'icon-repayment',
		width: ModalWidthType.PERCENTAGE20,
	},
]

/**权重第四梯度按钮 */
const weightLayer4 = [
	{
		type: OpeationBtnType.TEMPORARYPASSWORD,
		label: '获取动态密码',
		icon: 'icon-get-dynamic-password',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.ENABLEDEVICEBIND,
		label: '开启设备绑定',
		icon: 'icon-enable-device-binding',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.DISABLEDDEVICEBIND,
		label: '关闭设备绑定',
		icon: 'icon-turn-off-device-binding',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.DEVICELOGINLOG,
		label: '登录历史记录',
		icon: 'icon-login-history',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.CREATEACCOUNT,
		label: '生成账号',
		icon: 'icon-generate-account',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.SETPOSITION,
		label: '设置主职位',
		icon: 'icon-set-main-position',
		width: ModalWidthType.PERCENTAGE40,
	},
	{
		type: OpeationBtnType.SETCHARGEDEPT,
		label: '设置负责组织',
		icon: 'icon-set-up-responsible-organization',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.SETLEADER,
		label: '设置所属上级',
		icon: 'icon-set-up-superior',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.SETAPPLYDEPT,
		label: '设置应用组织',
		icon: 'icon-set-up-application-organization',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.APPLYPOSITION,
		label: '应用职位',
		icon: 'icon-application-position',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.VIEWEMPLOYEE,
		label: '查看成员',
		icon: 'icon-view-members',
		width: ModalWidthType.PERCENTAGE60,
	},
	{ type: OpeationBtnType.SETTAG, label: '设置标签', icon: 'icon-setting-tab', width: ModalWidthType.PERCENTAGE30 },
	{
		type: OpeationBtnType.SETDISTRIBUTOR,
		label: '设置经销商',
		icon: 'icon-setting-dealer',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.SETCONTACT,
		label: '变更负责人',
		icon: 'icon-change-responsible-person',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.SETAREA,
		label: '转换销售区域',
		icon: 'icon-convert-sales-area',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.BATCHUPDATE,
		label: '批量更新',
		icon: 'icon-batch-update',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.IMPORTBASICINFO,
		label: '导入基础信息',
		icon: 'icon-import-basic-information',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.IMPORTPRICESETTING,
		label: '导入价格设置',
		icon: 'icon-import-price-settings',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.UPVALIDITYTDATE,
		label: '更改有效期',
		icon: 'icon-change-validity-period',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.DIMENSIONVALUE,
		label: '维值',
		icon: 'icon-dimensional-value',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.BUDGETARYORGANIZATION,
		label: '预算编制',
		icon: 'icon-write',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.BUDGETARYADJUST,
		label: '预算调整',
		icon: 'icon-change-budget',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.RECALCULATEREVENUE,
		label: '重算',
		icon: 'icon-edit-amount',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.SUBMITAPPROVAL,
		label: '提交审批',
		icon: 'icon-submit-for-approval',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.ACTIVITYOVERAPPLICATION,
		label: '提交结案',
		icon: 'icon-submit-for-closure',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.STARTCANCELLATION,
		label: '发起核销审批',
		icon: 'icon-initiate-verification-approval',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.STOPCANCELLATION,
		label: '发起终止核销',
		icon: 'icon-initiate-verification-approval',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.CONFIRMVERIFICATION,
		label: '修改核销金额',
		icon: 'icon-edit-amount',
		hasPermission: true,
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.ASSIGNORDERS,
		label: '分配订单',
		icon: 'icon-assign-orders',
		width: ModalWidthType.PERCENTAGE60,
	},
	{ type: OpeationBtnType.SUBMIT, label: '提交', icon: 'icon-submit-to', width: ModalWidthType.PERCENTAGE20 },
	{
		type: OpeationBtnType.EXPORTALLOCATERECORDS,
		label: '调库记录',
		icon: 'icon-export-record',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.EXPORTALLOCATERETAILS,
		label: '调库明细',
		icon: 'icon-export-details',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.REFRINODELIVER,
		label: '冰箱设置未投放',
		icon: 'icon-refrigerator-settings-not-deployed',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.BULKTRANSFERFREEZERS,
		label: '冰箱批量转移',
		icon: 'icon-batch-transfer',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.SETREPAIRCOSTS,
		label: '设置维修费用',
		icon: 'icon-set-main-position',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.ADDREPORTER,
		label: '添加提报人',
		icon: 'icon-add',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.ADDTEMPORARYUPLOADER,
		label: '添加临时上传人员',
		icon: 'icon-add',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.ADDVIEWDETAILSUSER,
		label: '添加查看明细人员',
		icon: 'icon-add',
		width: ModalWidthType.PERCENTAGE60,
	},
	{
		type: OpeationBtnType.INTOINFOCONTRACT,
		label: '生成电子合同',
		icon: 'icon-basic-data',
		width: ModalWidthType.PERCENTAGE20,
	},
	{ type: OpeationBtnType.SIGNATURE, label: '签章', icon: 'icon-signature', width: ModalWidthType.PERCENTAGE20 },
	{ type: OpeationBtnType.CONTINUE, label: '续签', icon: 'icon-renewal', width: ModalWidthType.PERCENTAGE60 },
	{ type: OpeationBtnType.TOPPING, label: '置顶', icon: 'icon-topping', width: ModalWidthType.PERCENTAGE20 },
	{
		type: OpeationBtnType.CANCELTOPPING,
		label: '取消置顶',
		icon: 'icon-cancel-topping',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.UPDATESCHEDULE,
		label: '更新明细表',
		icon: 'icon-refresh',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.CONFIRM,
		label: '确认',
		icon: 'icon-submit-to',
		width: ModalWidthType.PERCENTAGE20,
	},
	{ type: OpeationBtnType.LOOK, label: '查看', icon: 'icon-view', width: ModalWidthType.PERCENTAGE60 },
	{
		type: OpeationBtnType.BATCHTRANSFER,
		label: '批量转批',
		icon: 'icon-convert-sales-area',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.BATCHAPPROVAL,
		label: '批量审批',
		icon: 'icon-batch-approval',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.SUBMITTERMINALORDER,
		label: '提交订单',
		icon: 'icon-submit-to',
		width: ModalWidthType.PERCENTAGE20,
	},
]

/**权重第五梯度按钮 */
const weightLayer5 = [
	{ type: OpeationBtnType.IMPORTSHIP, label: '导入发货', icon: 'icon-import', width: ModalWidthType.PERCENTAGE30 },
	{
		type: OpeationBtnType.EXPORTSHIP,
		label: '导出发货明细',
		icon: 'icon-export-details',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.IMPORTBILLHEAD,
		label: '导入开票抬头',
		icon: 'icon-export-invoice-header',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.IMPORTMODIWARPER,
		label: '导入修改保修期',
		icon: 'icon-import-and-modify-shelf-life',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.IMPORTREPAIRCOSTS,
		label: '导入维修费用',
		icon: 'icon-import-maintenance-costs',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.EXPORTINVENTORYRECORDS,
		label: '导出上报记录',
		icon: 'icon-export-record',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.EXPORTINVENTORYRECORDDETAILS,
		label: '导出上报记录明细',
		icon: 'icon-export-details',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.EXPORTDEMANDDETAILS,
		label: '导出需求明细',
		icon: 'icon-export-details',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.EXPORTFACTORYDEMANDSUMMARY,
		label: '导出工厂需求汇总',
		icon: 'icon-export-details',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.IMPORTAPPROVEL,
		label: '批量导入审批',
		icon: 'icon-batch-import',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.EXPORTCANCELLATIONRECORD,
		label: '导出核销记录',
		icon: 'icon-export-record',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.EXPORTFACTORYCANCELLATIONSUMMARY,
		label: '导出工厂核销汇总',
		icon: 'icon-export-details',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.EXPORTDETAILS,
		label: '导出订单明细',
		icon: 'icon-export-details',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.IMPORTAPPROVAL,
		label: '导入审批',
		icon: 'icon-batch-import',
		width: ModalWidthType.PERCENTAGE30,
	},
	{
		type: OpeationBtnType.IMPORTINVOICEHEADER,
		label: '导入修改开票抬头',
		icon: 'icon-import-basic-information',
		width: ModalWidthType.PERCENTAGE30,
	},
]

/**权重第六梯度按钮 */
const weightLayer6 = [
	{ type: OpeationBtnType.DOWNLOAD, label: '下载', icon: 'icon-download', width: ModalWidthType.PERCENTAGE20 },
]
/**权重第七梯度按钮 */
const weightLayer7 = [
	{ type: OpeationBtnType.REVOKE, label: '撤回', icon: 'icon-withdraw', width: ModalWidthType.PERCENTAGE20 },
]
/**权重第八梯度按钮 */
const weightLayer8 = [
	{
		type: OpeationBtnType.CLOSE,
		label: '关闭',
		icon: 'ele-CircleClose',
		width: ModalWidthType.PERCENTAGE20,
	},
	{ type: OpeationBtnType.CANCEL, label: '作废', icon: 'icon-cancel', width: ModalWidthType.PERCENTAGE20 },
	{
		type: OpeationBtnType.DISCARDTERMINALORDER,
		label: '作废',
		icon: 'icon-cancel',
		width: ModalWidthType.PERCENTAGE20,
	},
]

/**权重第九梯度按钮 */
const weightLayer9 = [
	{ type: OpeationBtnType.RESIGN, label: '离职', icon: 'icon-resign', width: ModalWidthType.PERCENTAGE20 },
	{
		type: OpeationBtnType.HANDOVER,
		label: '工作交接',
		icon: 'icon-assign-customers',
		width: ModalWidthType.PERCENTAGE60,
	},
]
/**权重第十梯度按钮 */
const weightLayer10 = [
	{ type: OpeationBtnType.IMPORT, label: '导入', icon: 'icon-import', width: ModalWidthType.PERCENTAGE30 },
	{ type: OpeationBtnType.IMPORTUPDATE, label: '导入更新', icon: 'icon-import', width: ModalWidthType.PERCENTAGE30 },
	{ type: OpeationBtnType.MUITILEADING, label: '导入', icon: 'icon-import', width: ModalWidthType.PERCENTAGE30 },
	{ type: OpeationBtnType.EXPORT, label: '导出', icon: 'icon-export', width: ModalWidthType.PERCENTAGE30 },
]
/**权重第十一梯度按钮 */
const weightLayer11 = [
	{ type: OpeationBtnType.ENABLE, label: '启用', icon: 'icon-enable', width: ModalWidthType.PERCENTAGE20 },
	{ type: OpeationBtnType.DISABLE, label: '停用', icon: 'icon-disable', width: ModalWidthType.PERCENTAGE20 },
	{ type: OpeationBtnType.TAGENABLE, label: '启用', icon: 'icon-enable', width: ModalWidthType.PERCENTAGE20 },
	{ type: OpeationBtnType.TAGDISABLE, label: '停用', icon: 'icon-disable', width: ModalWidthType.PERCENTAGE20 },
]
/**权重第十二梯度按钮 */
const weightLayer12 = [
	{
		type: OpeationBtnType.DELETE,
		label: '删除',
		icon: 'icon-delete',
		buttonType: 'danger',
		width: ModalWidthType.PERCENTAGE20,
	},
	{
		type: OpeationBtnType.TAGDELETE,
		label: '删除',
		icon: 'ele-Delete',
		buttonType: 'danger',
		width: ModalWidthType.PERCENTAGE20,
	},
]
const weightLayerList = [
	...weightLayer1,
	...weightLayer2,
	...weightLayer3,
	...weightLayer4,
	...weightLayer5,
	...weightLayer6,
	...weightLayer7,
	...weightLayer8,
	...weightLayer9,
	...weightLayer10,
	...weightLayer11,
	...weightLayer12,
]
/**按钮元数据定义列表 */
export const btnMetadataList = <BtnItem[]>[
	...weightLayerList,
	// TODO 未使用
	{ type: OpeationBtnType.MODIFY, label: '修改', icon: 'icon-edit', width: ModalWidthType.PERCENTAGE60 },

	// TODO  暂未使用
	{ type: OpeationBtnType.INVENTORY, label: '库存调库', icon: 'ele-Plus', width: ModalWidthType.PERCENTAGE60 },
]

/**
 * @description 全局按钮操作
 * @param permissionName 抽屉需要展示相应模块权限按钮
 * @returns  btnList 按钮类型配置列表
 * @returns  OperationBtnTypeFnMap 返回不同类型按钮的不同方法对象
 */
export const useOperationBtnList = (permissionName = undefined) => {
	// 小屏窗口默认增加宽度百分比
	const DEFAULT_WIDTH = 5
	const { width } = useWindowSize()

	const btnList = reactive(handlePermissions(btnMetadataList, [], permissionName)?.btnList)
	// 修改弹窗宽度方法
	const addWidthFn = (multiple = 1) => {
		btnList.map((item) => {
			item.defaultWidth = item.defaultWidth || item.width
			item.width = parseFloat(item.defaultWidth) + DEFAULT_WIDTH * multiple + '%'
		})
	}
	// 对宽度进行防抖处理防止触发频率过高
	watchDebounced(
		width,
		(val) => {
			console.log(`output->val-watchDebounced`, val)
			if (val <= 1200) {
				addWidthFn(4)
				return
			}
			if (val <= 1300) {
				addWidthFn(3)
				return
			}
			if (val <= 1400) {
				addWidthFn(2)
				return
			}
			if (val <= 1600) {
				addWidthFn(1)
				return
			}
			// 一直是大屏尺寸就不做处理
			for (const element of btnList) {
				if (!element?.defaultWidth) {
					break
				} else {
					//从小屏切换到大屏 重置默认宽度
					element.width = element.defaultWidth
				}
			}
		},
		{ debounce: 300, maxWait: 1000, immediate: true }
	)

	/**多选校验 */
	const Multiple = (length: number = 0, label: string): Boolean => {
		if (length === 0) {
			ElMessage.warning(`请勾选需要${label}的数据!`)
			return false
		}
	}

	/**单选校验 */
	const Single = (length: number = 0, label: string): Boolean => {
		if (length !== 1) {
			length === 0 && ElMessage.warning(`请勾选需要${label}的数据!`)
			length > 1 && ElMessage.warning('最多选择一条数据!')
			return false
		}
	}

	/**前置自定义业务校验 */
	const preOpenValidation = async (customValid: () => Promise<boolean>) => {
		try {
			// 业务代码-前置校验
			await customValid()
			return true
		} catch (error) {
			return false
		}
	}

	const approvalStatusValidErrorMsg = (
		approvalStatusValid: number[],
		approvalStatusMap: Pick<ApprovalStatusValidOptions, 'approvalStatusMap'>,
		isMerge?: boolean,
		isRelativeValidLabel?: boolean
	) => {
		let statusNameList = Object.entries(approvalStatusMap)
			.reduce((prev, item) => {
				console.log(`output->item`, item)
				if ((approvalStatusValid as number[]).includes(+item[0])) {
					console.log(`output->item[1]`, item[1])
					prev.push(item[1]?.name)
				}
				return prev
			}, [])
			?.filter(Boolean)
		// 获取正确状态相反值的所有名称
		if (isRelativeValidLabel) {
			const allStatusName = Object.entries(approvalStatusMap).map((item) => item[1].name)
			// 取差集
			const relativeLabel = allStatusName.filter((item) => !new Set(statusNameList).has(item))
			statusNameList = relativeLabel
		}
		if (isMerge) {
			return statusNameList.join('、')
		}
		const msg = `只有${statusNameList.join('、')}可进行该操作`
		console.log(`output->msg`, msg)
		ElMessage.warning(msg)
		return msg
	}
	/**
	 *公共方法
	 * @param list 表格勾选的数据
	 * @param options 过滤的状态
	 * @param statusMap 状态的集合
	 * @param statusName 过滤状态的名字
	 * @returns
	 */
	const commonStatusValid = (
		list: {
			operationStatus?: number
			approvalStatus?: number
			[key: string]: any
		}[],
		options: ApprovalStatusValidOptions,
		statusMap?: any,
		statusName?: string
	) => {
		const { valid, isMerge = false, isRelativeValidLabel = false } = options
		let approvalStatusValid = valid
		if (typeof valid === 'number') {
			approvalStatusValid = [approvalStatusValid] as number[]
		}
		/** 业务状态一定要标明清楚 statusName */
		// list 传入的selectList() (存在valid中的值返回false，否则返回true)
		let isValid = list.every((item) => {
			const everyValid = (approvalStatusValid as number[]).includes(item[statusName])
			return everyValid
		})
		console.log(`output->isValid`, isValid)
		// 没有校验通过就需要反馈到外部,给出提示语
		if (!isValid) {
			return approvalStatusValidErrorMsg(approvalStatusValid as number[], statusMap, isMerge, isRelativeValidLabel)
		}
		// 合并的值需要反馈到外部，给提示语
		return isMerge ? '' : true
	}
	// 审批的状态过滤
	const ApprovalStatusValid = (list: ApprovalListStatus[], options: ApprovalStatusValidOptions) => {
		const { valid, approvalStatusMap = ApprovalStatusMap } = options
		// approvalStatus 审批流字段 不可更改 如有不符尽快向上反馈
		return commonStatusValid(list, options, approvalStatusMap, 'approvalStatus')
	}
	// 页面类型区分
	const drawerTypeDistinguish = (type) => {
		const drawerTypeOjbect = {
			[DrawerType.FEEBUDGETARYODD]: budgetaryStatusMap,
			[DrawerType.FEEAPPLICATIONODD]: applicationOddStatusMap,
			[DrawerType.CANCELLATIONBYACTIVITY]: cancellationStatusMap,
			[DrawerType.CANCELLATIONBYCUSTOMIZE]: cancellationStatusMap,
			[DrawerType.CANCELLATIONBYAPPLICATIONODD]: cancellationStatusMap,
			[DrawerType.CANCELLATIONBYPROMOTIONPOLICY]: cancellationStatusMap,
			[DrawerType.POLICYAPPLICATION]: policyStatusMap,
			[DrawerType.PROMOTIONSALESREPORT]: policyUploadStatusMap,
			[DrawerType.PROPOSALAPPLICATION]: AcctiveStatusMap,
			[DrawerType.ACTIVITYAPPLYFOR]: expenseActivityStatusMap,
			[DrawerType.CHANNELINVENTORYRECORD]: channelInventoryRecordStatusMap,
			[DrawerType.ACTIVITYEXCUTEPROJECT]: ActivitySystemPresetMap,
			[DrawerType.REFRIGERATORSHIPPINGPLAN]: RefridgeratorPlanStatusMap,
			[DrawerType.CONTRACTLIST]: ContractApprovalStatusMap,
			[DrawerType.ACTIVITYOVERAPPLICATION]: ActivityOverApplicationStatusMap,
			[DrawerType.TERMINALORDERS]: channelInventoryRecordStatusMap,
		}
		return drawerTypeOjbect[type]
	}
	/**
	 * @description 通用业务页面状态提示函数
	 * @param list //selectList选中的数据
	 * @param options // 其他参数对象
	 */
	const profeessionalStatusValid = (
		list: {
			operationStatus?: number
			[key: string]: any
		}[],
		options: ApprovalStatusValidOptions
	) => {
		// 根据各自页面Drawer判断状态map
		const { valid, type, statusName } = options
		let pageStatsMap = drawerTypeDistinguish(type)
		return commonStatusValid(list, options, pageStatsMap, statusName)
	}

	/**执行操作时，聚合审批状态与业务状态逻辑校验及提示 */
	const mergeStatusValid = (
		list: {
			operationStatus?: number
			[key: string]: any
		}[],
		options: Partial<
			ApprovalStatusValidOptions & {
				getRelativeCustomLabel: boolean // 是否获取业务状态校验值名称
				getRelativeLabel: boolean // 是否获取审批状态校验值名称
			}
		>
	) => {
		const {
			valid,
			type,
			customStatusValid,
			approvalStatusMap,
			statusName,
			getRelativeCustomLabel = false,
			getRelativeLabel = false,
			...rest
		} = options
		// 优先校验审批状态
		// valid 审批状态集合
		let approvalStatusName =
			valid &&
			ApprovalStatusValid(list as ApprovalListStatus[], {
				...rest,
				valid,
				approvalStatusMap,
				isMerge: true,
				isRelativeValidLabel: getRelativeLabel,
			})
		// customStatusValid 业务状态集合
		let profeessionalStatusName =
			customStatusValid &&
			profeessionalStatusValid(list, {
				...rest,
				type,
				statusName,
				valid: customStatusValid,
				isMerge: true,
				isRelativeValidLabel: getRelativeCustomLabel,
			})
		console.log(
			`output->approvalStatusName,profeessionalStatusName`,
			approvalStatusName,
			profeessionalStatusName,
			customStatusValid
		)
		let statusNameJoin =
			approvalStatusName && profeessionalStatusName
				? `${approvalStatusName}、${profeessionalStatusName}`
				: approvalStatusName || profeessionalStatusName
		// 不存在错误状态
		if (!statusNameJoin) return true
		// 获取反选值名称 全取反集合为一条文案
		if (getRelativeCustomLabel && getRelativeLabel) {
			return statusNameJoin
		}
		// 获取反选值名称 部分取反去做区分
		if (getRelativeCustomLabel || getRelativeLabel) {
			return {
				customLabel: profeessionalStatusName,
				approvalLabel: approvalStatusName,
			}
		}
		const msg = `只有${statusNameJoin}可进行该操作`
		ElMessage.warning(msg)
		return msg
	}

	/**
	 * @description 处理按钮  公共操作
	 * @returns 返回 不同类型按钮的不同方法对象
	 */
	const operationBtnTypeFnMap = {
		// 新增
		[OpeationBtnType.ADD]: {
			defaultPrevValidate: () => {
				return true
			},
			preOpenValidation,
		},
		//新增自定义核销
		[OpeationBtnType.CUSTOMIZECANCELLATIONADD]: {
			defaultPrevValidate: () => {
				return true
			},
			preOpenValidation,
		},
		// 发起核销
		[OpeationBtnType.STARTCANCELLATION]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 终止核销
		[OpeationBtnType.STOPCANCELLATION]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 查看成员
		[OpeationBtnType.VIEWEMPLOYEE]: {
			defaultPrevValidate: Single,
		},
		// 编辑
		[OpeationBtnType.EDIT]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		// 调整
		[OpeationBtnType.ADJUST]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		// 预算编制
		[OpeationBtnType.BUDGETARYORGANIZATION]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		// 预算调整
		[OpeationBtnType.BUDGETARYADJUST]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		// 重算营业额
		[OpeationBtnType.RECALCULATEREVENUE]: {
			defaultPrevValidate: Single,
		},
		// 更改有效期
		[OpeationBtnType.UPVALIDITYTDATE]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 发起
		[OpeationBtnType.INITIATE]: {
			defaultPrevValidate: Single,
		},
		// 分配客户
		[OpeationBtnType.ASSIGNCUSTOMERS]: {
			defaultPrevValidate: Single,
		},
		[OpeationBtnType.DEVICELOGINLOG]: {
			defaultPrevValidate: Single,
		},
		// 查看发货记录
		[OpeationBtnType.SHOWSHIP]: {
			defaultPrevValidate: Single,
		},
		// 转移路线
		[OpeationBtnType.TRANSFERROUTE]: {
			defaultPrevValidate: Single,
		},
		// 查看
		[OpeationBtnType.LOOK]: {
			defaultPrevValidate: Single,
		},
		//导出订单明细
		[OpeationBtnType.EXPORTDETAILS]: {
			defaultPrevValidate: () => {
				return true
			},
		},
		// 去到维值页面
		[OpeationBtnType.DIMENSIONVALUE]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		// 删除
		[OpeationBtnType.DELETE]: {
			defaultPrevValidate: Multiple,

			preOpenValidation,
		},
		// 提交订单
		[OpeationBtnType.SUBMITTERMINALORDER]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 作废
		[OpeationBtnType.CANCEL]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 作废终端订单
		[OpeationBtnType.DISCARDTERMINALORDER]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 提交
		[OpeationBtnType.SUBMIT]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 启用
		[OpeationBtnType.ENABLE]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 批量更新
		[OpeationBtnType.BATCHUPDATE]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 停用
		[OpeationBtnType.DISABLE]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 变更负责人
		[OpeationBtnType.SETCONTACT]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 设置标签
		[OpeationBtnType.SETTAG]: {
			defaultPrevValidate: Multiple,
		},
		// 设置经销商
		[OpeationBtnType.SETDISTRIBUTOR]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 转化销售区域
		[OpeationBtnType.SETAREA]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 重置密码
		[OpeationBtnType.RESETPASSWORD]: {
			defaultPrevValidate: Multiple,
		},
		// 审核
		[OpeationBtnType.APPROVAL]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 生成账号
		[OpeationBtnType.CREATEACCOUNT]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 设置主职位
		[OpeationBtnType.SETPOSITION]: {
			defaultPrevValidate: Single,
		},
		// 应用职位
		[OpeationBtnType.APPLYPOSITION]: {
			defaultPrevValidate: Single,
		},
		// 设置负责组织
		[OpeationBtnType.SETCHARGEDEPT]: {
			defaultPrevValidate: Single,
		},
		// 设置所属上级
		[OpeationBtnType.SETLEADER]: {
			defaultPrevValidate: Multiple,
		},
		// 设置负责组织
		[OpeationBtnType.SETAPPLYDEPT]: {
			defaultPrevValidate: Single,
		},
		// 离职
		[OpeationBtnType.RESIGN]: {
			defaultPrevValidate: Multiple,
		},
		// 工作交接
		[OpeationBtnType.HANDOVER]: {
			defaultPrevValidate: Single,
		},
		// 提交审核
		[OpeationBtnType.SUBMITAPPROVAL]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 检核(促销上报)
		[OpeationBtnType.CHECKREVIEW]: {
			defaultPrevValidate: Multiple,
		},
		// 复制
		[OpeationBtnType.COPY]: {
			defaultPrevValidate: Single,
		},
		// 复制并新建
		[OpeationBtnType.COPYANDNEW]: {
			defaultPrevValidate: Single,
		},
		[OpeationBtnType.POLICYENTORY]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		// 导入
		[OpeationBtnType.IMPORT]: {
			defaultPrevValidate: () => {
				return true
			},
		},
		// 导出
		[OpeationBtnType.EXPORT]: {
			defaultPrevValidate: () => {
				return true
			},
		},
		// 导出核销记录
		[OpeationBtnType.EXPORTCANCELLATIONRECORD]: {
			preOpenValidation,
		},
		// 导出出厂记录核销汇总
		[OpeationBtnType.EXPORTFACTORYCANCELLATIONSUMMARY]: {
			preOpenValidation,
		},
		/**@deprecated 未使用 */
		//修改READ
		[OpeationBtnType.MODIFY]: {
			defaultPrevValidate: Single,
		},
		//标记为已读
		[OpeationBtnType.READ]: {
			defaultPrevValidate: Multiple,
		},
		// 续签
		[OpeationBtnType.CONTINUE]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		//设置维修费用
		[OpeationBtnType.SETREPAIRCOSTS]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		//冰箱未投放设置
		[OpeationBtnType.REFRINODELIVER]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		// 编辑标签
		[OpeationBtnType.TAGEDIT]: {
			defaultPrevValidate: Single,
		},
		// 删除标签
		[OpeationBtnType.TAGDELETE]: {
			defaultPrevValidate: Multiple,
		},
		// 启用标签
		[OpeationBtnType.TAGENABLE]: {
			defaultPrevValidate: Multiple,
		},
		// 停用标签
		[OpeationBtnType.TAGDISABLE]: {
			defaultPrevValidate: Multiple,
		},
		// 生成电子合同
		[OpeationBtnType.INTOINFOCONTRACT]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		//分配订单
		[OpeationBtnType.ASSIGNORDERS]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		// 提交结案
		[OpeationBtnType.ACTIVITYOVERAPPLICATION]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},

		//关闭
		[OpeationBtnType.CLOSE]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		[OpeationBtnType.TEMPORARYPASSWORD]: {
			defaultPrevValidate: Single,
		},
		[OpeationBtnType.SIGNATURE]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		[OpeationBtnType.DOWNLOAD]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		[OpeationBtnType.PUBLIC]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		[OpeationBtnType.COPYANNOUNCEMENT]: {
			defaultPrevValidate: Single,
		},
		[OpeationBtnType.TOPPING]: {
			defaultPrevValidate: Multiple,
		},
		[OpeationBtnType.CANCELTOPPING]: {
			defaultPrevValidate: Multiple,
		},
		[OpeationBtnType.REVOKE]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		[OpeationBtnType.CONFIRMVERIFICATION]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		[OpeationBtnType.EDITPLAN]: {
			defaultPrevValidate: Single,
		},
		[OpeationBtnType.ADDTEMPORARYUPLOADER]: {
			defaultPrevValidate: Single,
		},
		[OpeationBtnType.UPDATESCHEDULE]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		[OpeationBtnType.CONFIRM]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		[OpeationBtnType.EXPORTDEMANDDETAILS]: {
			defaultPrevValidate: Multiple,
		},
		[OpeationBtnType.EXPORTFACTORYDEMANDSUMMARY]: {
			defaultPrevValidate: Multiple,
		},
		[OpeationBtnType.ENABLEDEVICEBIND]: {
			defaultPrevValidate: Multiple,
		},
		[OpeationBtnType.CLEARDEVICEBIND]: {
			defaultPrevValidate: Multiple,
		},
		[OpeationBtnType.DISABLEDDEVICEBIND]: {
			defaultPrevValidate: Multiple,
		},
		[OpeationBtnType.REPAYMENT]: {
			defaultPrevValidate: Single,
			preOpenValidation,
		},
		[OpeationBtnType.BATCHTRANSFER]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		[OpeationBtnType.BATCHAPPROVAL]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		[OpeationBtnType.SETVISITEDUNUSUAL]: {
			defaultPrevValidate: Multiple,
			preOpenValidation,
		},
		[OpeationBtnType.EXPORTCANCELLATIONRECORD]: {
			preOpenValidation,
		},
		[OpeationBtnType.EXPORTFACTORYCANCELLATIONSUMMARY]: {
			preOpenValidation,
		},
	}
	/**
	 * @description 提供当前选中按钮逻辑函数
	 * @param btnType 按钮类型
	 * @returns 返回当前按钮方法
	 */
	const selectCommonBtnTypeFn = (btnType: OpeationBtnType) => {
		return operationBtnTypeFnMap[btnType] ?? {}
	}
	/** 业务模块按钮权限过滤 */
	const btnPermissionFilter = (needCheckBtnList: OpeationBtnType[]) => {
		return btnList.filter((item) => needCheckBtnList?.includes(item.type))
	}

	return {
		btnList,
		btnPermissionFilter,
		operationBtnTypeFnMap,
		ApprovalStatusValid,
		profeessionalStatusValid,
		mergeStatusValid,
		selectCommonBtnTypeFn,
	}
}
