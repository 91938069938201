// * 公共方法

import { useAsyncState } from '@vueuse/core'
import { ElMessage, UploadProps } from 'element-plus'
import { cloneDeep } from 'lodash-es'
import { Base64 } from 'js-base64'
import { getFileUrlParam } from '@/api/serveApi/contractManagement/contractList'
import { LABELCOLOR } from '@/config/Constantconfig'
import { OpeationBtnType } from '@/hooks/useOperationBtnList'
import dayjs from 'dayjs'
import { DateType } from '@/utils/format'

/**遍历方式 */
export enum TraversalMethod {
	/**先序遍历 */
	PREORDER = 'PREORDER',
	/**后序遍历 */
	POSTORDER = 'POSTORDER',
}
interface Options {
	sliceNumber: number
	traversalMethod: TraversalMethod
}
// * 下划线转驼峰
export const formatToHump = (str: string): string => {
	return str.replace(/_(\w)/g, (_, letter) => letter.toUpperCase())
}

//图片格式和大小 前置验证
export const beforeAvatarUpload: UploadProps['beforeUpload'] = (rawFile, maxSize = 10) => {
	if (!['image/jpeg', 'image/png', 'image/jpeg'].includes(rawFile.type)) {
		ElMessage.error('只能上传格式为jpg,png和jpeg格式的图片')
		return false
	}
	if (rawFile.size / 1024 / 1024 > maxSize) {
		ElMessage.error(`图片大小不能超过${maxSize}M!`)
		return false
	}
	return true
}

// 当前值为零时 转换为undefined
export const defaultZeroConversionUndefined = <T>(val: T): T | undefined => {
	if (typeof val === 'number') {
		val = val === 0 ? undefined : val
	}
	return val
}

// 转换对象子属性值为undefined
export const convertObjectChildToUndefined = (obj: Record<string, unknown>): Record<string, unknown> => {
	return Object.entries(obj).reduce((prev, item) => {
		prev[item[0]] = defaultZeroConversionUndefined(item[1])
		return prev
	}, {})
}
export const windowOpen = (url) => {
	window.open(url)
}
// 获取合同预览地址
const { execute: fetchGetFileUrlParam } = useAsyncState(
	(arg) => getFileUrlParam({ ...arg }),
	{
		code: 0,
		msg: '',
		data: '',
	},
	{
		immediate: false,
	}
)
/**使用服务端预览功能 */
export const previewFileUrl = async (url) => {
	if (!url) return
	let openUrl = url
	// 如果没有前缀就加
	if (!openUrl.includes(import.meta.env.VITE_CDN_BASE_PATH)) openUrl = import.meta.env.VITE_CDN_BASE_PATH + url

	if (!url.includes('.html')) {
		// 获取预览服务临时地址
		const tempUrlRes = await fetchGetFileUrlParam(0, { fileUrl: openUrl })
		const { data } = tempUrlRes
		openUrl = import.meta.env.VITE_CDN_BASE_PATH + data
		openUrl =
			import.meta.env.VITE_FILE_BASE_PATH +
			'/onlinePreview?url=' +
			encodeURIComponent(Base64.encode(encodeURIComponent(`${openUrl}`)))
	}
	windowOpen(openUrl)
}

/**
 * @description 处理两个数组合并相同keyName数据
 * @param arr1 默认数组1
 * @param arr2 需要填充数组2
 * @param keyName 唯一值名称
 * @returns 返回合并完成的数组
 */
export const mergeArrays = (arr1: Record<string, any>[], arr2: Record<string, any>[], keyName = 'id') => {
	const mergedMap = new Map()

	// 合并第一个数组
	for (const obj of arr1) {
		const objId = obj[keyName]
		if (mergedMap.has(objId)) {
			// 如果ID已存在，将对象合并到现有对象中
			mergedMap.set(objId, Object.assign(mergedMap.get(objId), obj))
		} else {
			// 如果ID不存在，将对象添加到Map中
			mergedMap.set(objId, { ...obj })
		}
	}

	// 合并第二个数组
	for (const obj of arr2) {
		const objId = obj[keyName]
		if (mergedMap.has(objId)) {
			// 如果ID已存在，将对象合并到现有对象中
			mergedMap.set(objId, Object.assign(mergedMap.get(objId), obj))
		} else {
			// 如果在默认数组中不存在，不将对象添加到Map中
			// mergedMap.set(objId, { ...obj })
		}
	}

	// 将Map中的值转换回数组
	const mergedArray = Array.from(mergedMap.values())

	return mergedArray
}

/**
 * @description 静态资源路径映射统一，方便后续改版
 * @param url 路径资源
 * @param type 路径前缀  1.基础公用 2.使用  /api 拼接 3.用于图片下载动作
 */
export const getFullPath = (url, type = 1) => {
	const envObject = {
		1: import.meta.env.VITE_CDN_BASE_PATH,
		2: import.meta.env.VITE_BASE_API,
		3: import.meta.env?.VITE_CDN_DOWNLOAD_PATH,
	}
	if (url) {
		// 非生产环境，图片下载类型不走下载地址，走默认CDN地址兼容了下载方式
		if (type === 3) {
			if (import.meta.env.VITE_ENV !== 'production') {
				type = 1
			}
			url = url + '?response-content-disposition=attachment'
		}

		if (url?.indexOf('http://') === 0 || url?.indexOf('https://') === 0) {
			return url
		} else if (url?.indexOf('/') === 0) {
			console.log(envObject[type] + url, 'i地址')
			return envObject[type] + url
		} else {
			return envObject[type] + '/' + url
		}
	}
	return ''
}

/**十进制转成二进制=》数组类型 */
export const getBinaryBitwiseOperation = (num, arrayLen = 1) => {
	let binaryArray = []
	while (num > 0) {
		binaryArray.unshift(num % 2) // 取余操作并将结果添加到数组的开头
		num = num >> 1 // 右移一位
	}
	console.log(`output->binaryArray`, binaryArray)

	//兼容后续新增 向前追加 1100=》01100 故反转 (从四个选项 变成五个选项)
	binaryArray = binaryArray.reverse()
	// 转换格式
	const listToString = binaryArray.toString()
	// 填充满所有位 向前填充0，使得总长度为选择的位数
	binaryArray = listToString?.padStart(arrayLen, '0').split(',').map(Number) ?? []
	return binaryArray
}

/** 二进制数组类型转成十进制 */
export const getIntValueBitwiseOperation = (binaryArray) => {
	// binaryArray[i] 为二进制
	// eg 二进制左移
	// binaryArray[0] = 1：这是二进制中的 001。
	// binaryArray[1] = 1 << 1：左移一位，得到 010。
	// binaryArray[2] = 0 << 2：左移两位，得到 000。
	// binaryArray[3] = 1 << 3：左移三位，得到 1000。
	// 二进制 按位或运算（| 是 JavaScript 中的按位或运算符。按位或运算符将两个数的每个对应位进行按位或操作，结果的每一位是两个数对应位按位或的结果。）
	// eg
	// 001   (binaryArray[0])
	// 010   (binaryArray[1])
	// 000   (binaryArray[2])
	// 1000  (binaryArray[3])
	// -----
	// 1110 =>十进制为（13）
	let result = 0
	for (let i = 0; i < binaryArray.length; i++) {
		result = result | (binaryArray[i] << i)
	}
	return result
}

/**
 * @description 拼接动态列函数 （ 递归遍历 数据）
 * @param list 字段列表
 */
export const recursivetraversal = (list: Array<ColumnFieldType>, optionsParams) => {
	const options = {
		isAssociation: false,
		parentValue: '',
		slotCallback: undefined,
		slotNameCallback: undefined,
		...optionsParams,
	}
	const { isAssociation, parentValue, slotCallback, slotNameCallback } = options

	return list?.length > 0
		? list.map((item) => {
				// 子属性 关联父属性
				const parentValueAssociation = isAssociation ? parentValue : ''
				let prop = parentValueAssociation ? `${parentValueAssociation}.${item.value}` : item.value
				const slotName = slotNameCallback ? slotNameCallback(prop, item) : prop
				prop = slotCallback ? slotCallback(prop, item) : prop
				return {
					isShow: true,
					isLock: false,
					isDyField: true,
					...item,
					label: item.label,
					slotName,
					prop,
					showOverflowTooltip: true,
					children:
						item.children &&
						recursivetraversal(item.children, {
							isAssociation,
							parentValue: item.value,
							slotCallback,
							slotNameCallback,
						}),
				}
			})
		: []
}

/**
 * @typedef {Object} Options
 * @property {number} [sliceNumber=1] - 分片数，默认为 1
 * @property {TraversalMethod} [traversalMethod=TraversalMethod.PREORDER] - 遍历方式，默认为先序遍历
 */
/**
 * @description 大数组分片执行交互（解决长任务占用UI线程造成页面假死）
 * @param {Array} nestedArray 需要分片数组
 * @param {function} callback 对每次分片执行函数
 * @param {Options} [options] - 函数选项
 * @returns {Array} nestedArray 返回整个数据
 */
export const processNestedArray = async <T>(
	nestedArray: T[],
	callback: (e) => unknown,
	options?: Options
): Promise<T[]> => {
	const { sliceNumber, traversalMethod } = {
		sliceNumber: 1,
		traversalMethod: TraversalMethod.PREORDER,
		...options,
	} as Options
	const processChunk = (nestedArray: T[], start: number, end: number) => {
		for (let i = start; i < end; i++) {
			if (traversalMethod === TraversalMethod.PREORDER) {
				// 自定义执行每项交互
				callback(nestedArray[i])
			}
			// 如果数组项包含子数组，递归调用 processChunk 处理子数组
			if (nestedArray[i]?.children?.length > 0) {
				processChunk(nestedArray[i].children, 0, nestedArray[i].children.length)
			}
			if (traversalMethod === TraversalMethod.POSTORDER) {
				// 自定义执行每项交互
				callback(nestedArray[i])
			}
		}
	}
	const totalItems = nestedArray.length
	const processInChunks = async (start: number, end: number) => {
		//每次分片，递归交互
		processChunk(nestedArray, start, end)

		if (end < totalItems) {
			// 使用 requestAnimationFrame 模拟下一个时间分片
			await new Promise((resolve) => {
				requestAnimationFrame(() => {
					resolve(processInChunks(end, end + sliceNumber))
				})
			})
		}
	}
	// 开始第一次时间分片
	await processInChunks(0, sliceNumber)

	return nestedArray
}
/**
 * 判断是否为空值
 * @param value
 * @returns {boolean} true: 空值, false: 不为空值
 */
export const isNull = (value: unknown) => {
	switch (typeof value) {
		case 'string':
			return value.length === 0
		case 'number':
			return Number.isNaN(value)
		case 'bigint':
		case 'boolean':
		case 'symbol':
		case 'function':
			return false
		case 'undefined':
			return true
		case 'object':
			if (Array.isArray(value)) {
				return value.length === 0
			} else if (Object.prototype.toString.call(value) === '[object Object]') {
				return JSON.stringify(value) === '{}'
			}
			return value === null
		default:
			return true
	}
}

/**
 * 根据接口返回颜色选取对应背景色、字体色、边框色
 * @param color
 * @returns {boolean} 对应的背景色、字体色、边框色
 */
export const getLabelColor = (color: unknown) => {
	const defaultColor = '#3783FF'
	const labelColor = Object.keys(LABELCOLOR).find((item) => item === color)
	if (labelColor) {
		return LABELCOLOR[labelColor]
	} else {
		return LABELCOLOR[defaultColor]
	}
}

/**
 * 复制内容到剪贴板（同crtl + c）
 * @param content 具体的内容
 */
export const copyData = (content: string) => {
	const Url2 = content //每一行的某个值，如选中的当前行的url
	const oInput = document.createElement('input') //创建一个隐藏input（重要！）
	oInput.value = Url2 //赋值
	document.body.appendChild(oInput)
	oInput.select() // 选择对象
	document.execCommand('Copy') // 执行浏览器复制命令
	oInput.className = 'oInput'
	oInput.style.display = 'none'
	ElMessage.success('已复制')
}

/**
 * 处理部门数据，新增时不显示停用数据，编辑时只展示当前已勾选的停用数据
 * @param data 部门数据，必传
 * @param type 类型：非必传，新增时不显示已停用数据，编辑时只展示当前已选的停用数据。若为空，则显示所有数据
 * @param currentIds 非必传（类型为编辑必传）当前已选中的部门id
 * @param props 非必传，对应字段配置
 */
export const handleDepartData = ({
	data,
	type,
	currentIds = [],
	props = {
		label: 'name',
		value: 'departmentId',
		children: 'subDept',
		status: 'status',
	},
}: {
	data: any[]
	type?: OpeationBtnType
	currentIds?: string | string[]
	props?: {
		[key: string]: string
	}
}) => {
	if (!data) return []

	const departList = cloneDeep(data)
	return departList?.filter((item) => {
		if (item?.[props.children]?.length > 0) {
			item[props.children] = handleDepartData({
				data: item?.[props.children] ?? [],
				type,
				currentIds,
				props,
			})
		}
		if (type === OpeationBtnType.ADD) {
			return item?.[props.status] === 1
		} else if (type === OpeationBtnType.EDIT) {
			item.disabled = item?.[props.status] !== 1
			return item?.[props.status] === 1 || currentIds?.includes(item?.[props.value])
		}
		return true
	})
}

// 获取近几天时间范围
export const getDateRange = (days: number, formatType = DateType.YMD) => {
	const endDate = dayjs().format(formatType) // 当前日期
	const startDate = dayjs()
		.subtract(days - 1, 'day')
		.format(formatType)

	return [`${startDate}`, `${endDate}`]
}

