<template>
	<el-dialog
		:model-value="modelValue"
		title="添加产品"
		width="60%"
		:modal="true"
		:close-on-click-modal="false"
		append-to-body
		@close="handleClose"
	>
		<div class="flex flex-col">
			<QueryForm class="mb-md" @search="handleCurrentChange" @reset="resetData">
				<QueryFormItem>
					<XZLInput
						v-model="searchParam.code"
						placeholder="产品编号"
						:disabled="!!(disbledParams && defaultParams['code'])"
						clearable
					></XZLInput>
				</QueryFormItem>
				<QueryFormItem>
					<XZLInput
						v-model="searchParam.name"
						:disabled="!!(disbledParams && defaultParams['name'])"
						placeholder="产品名称"
						clearable
					></XZLInput>
				</QueryFormItem>
				<QueryFormItem>
					<XZLInput
						v-model="searchParam.specification"
						:disabled="!!(disbledParams && defaultParams['specification'])"
						placeholder="规格型号"
						clearable
					></XZLInput>
				</QueryFormItem>
				<QueryFormItem>
					<XZLSelect
						class="w-full"
						v-model="searchParam.category"
						placeholder="产品品类"
						:disabled="!!(disbledParams && defaultParams['category'])"
						multiple
						collapse-tags
						collapse-tags-tooltip
						filterable
						clearable
						:joinList="dictionaryMap[DictionaryType.PRODUCTCATEGORY]"
						:options="{ defaultValue: 'optionId', defaultLabel: 'optionValue' }"
					></XZLSelect>
				</QueryFormItem>
				<QueryFormItem>
					<XZLSelect
						class="w-full"
						v-model="searchParam.brand"
						placeholder="产品品牌"
						:disabled="!!(disbledParams && defaultParams['brand'])"
						multiple
						collapse-tags
						collapse-tags-tooltip
						filterable
						clearable
						:joinList="dictionaryMap[DictionaryType.BRANDING]"
						:options="{ defaultValue: 'optionId', defaultLabel: 'optionValue' }"
					></XZLSelect>
				</QueryFormItem>
			</QueryForm>
			<FormProTable
				ref="tableRef"
				class="flex-1"
				:border="true"
				v-model:columns="columns"
				showCheckNumber
				:checkNumber="checkList.length"
				:request="{ api: getProductList, params: { immediate: false } }"
				@select="selectionChange"
				@select-all="selectAll"
			/>
		</div>
		<template #footer>
			<span class="dialog-footer">
				<el-button text @click="handleClose">取消</el-button>
				<el-button type="primary" @click="handleConfirm">确定</el-button>
			</span>
		</template>
	</el-dialog>
</template>

<script setup lang="tsx">
import { ref, reactive, onMounted, watch, nextTick } from 'vue'
import { concat, uniqBy } from 'lodash-es'
import { ElMessage } from 'element-plus'
import { usePageDrawer } from '@/hooks/usePageDrawer/index'
import { DrawerType } from '@/hooks/usePageDrawer/types'
import { TableColumnProps } from '../proxyComponents/XZLTable/types'

import QueryForm from '@/components/queryForm/index.vue'
import QueryFormItem from '@/components/queryForm/item.vue'

import { getProductList, getProductByIds } from '@/api/serveApi/basicInformation/product/productList'
import { useDictionaryType, DictionaryType } from '@/hooks/useDictionaryType'

interface Props {
	modelValue: boolean
	rowIds?: number[] // 已勾选的产品id集合
	checkableIds?: number[] // 不可勾选的产品id集合
	defaultParams?: Partial<SearchParam> // 搜索条件
	disbledParams?: boolean // 如果有默认搜索条件时，默认搜索条件是否可修改
	addPolicyPromotionType?: number
	ids?: number[] // 你将选择的产品，只能从这id里面·的产品里选择
}

interface SearchParam {
	page: number
	pageSize: number
	code: string
	name: string
	specification: string
	category: number[]
	brand: number[]
}

class InitSearchParam implements SearchParam {
	page: number = 1
	pageSize: number = 20
	code: string = '' // 产品编号
	name: string = '' // 产品名称
	specification: string = '' // 规格型号
	category: number[] = [] // 产品品类
	brand: number[] = [] // 产品品牌
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'confirm'])

const { drawerOpen } = usePageDrawer()
const { dictionaryMap } = useDictionaryType()

const tableRef = ref()
const searchParam = reactive<InitSearchParam>(new InitSearchParam())
const checkList = ref([])
const columns = ref<TableColumnProps[]>([
	{
		type: 'selection',
		reserveSelection: true,
		selectable: ({ row }) => {
			return !props.checkableIds?.includes(row?.ID)
		},
	},
	{
		label: '序号',
		type: 'index',
		index: (index) => {
			const pageable = tableRef.value.pageable ?? {}
			return pageable.pageSize * (pageable?.page - 1) + index + 1
		},
	},
	{
		label: '产品编号',
		prop: 'code',
		render: ({ row }) => {
			return (
				<el-link
					type="primary"
					onClick={() =>
						drawerOpen(DrawerType.PRODUCTLIST, {
							title: row.name,
							props: {
								rowId: row.ID,
							},
							selectList: [{ ...row }],
						})
					}
				>
					{row.code}
				</el-link>
			)
		},
	},
	{ label: '产品名称', prop: 'name' },
	{ label: '产品品牌', prop: 'brandName' },
	{ label: '产品品类', prop: 'categoryName' },
	{ label: '规格型号', prop: 'specification' },
	{ label: '单位', prop: 'unitName' },
])

watch(
	() => props.defaultParams,
	(newVal) => {
		if (newVal) {
			for (const key in searchParam) {
				if (newVal[key]) searchParam[key] = newVal[key]
			}
		}
	},
	{ deep: true, immediate: true }
)

watch(
	[checkList, () => tableRef.value?.tableState?.list],
	(newVal) => {
		if (newVal[0]?.length > 0 && newVal[1]?.length > 0) {
			nextTick(() => {
				const ids: number[] = checkList.value.map((o) => o.ID)
				tableRef.value?.tableState?.list.forEach((row) => {
					if (ids.includes(row.ID)) {
						tableRef.value.element?.XZLTable!.toggleRowSelection(row, true)
					}
				})
			})
		}
	},
	{ deep: true }
)

onMounted(() => {
	if (props.rowIds?.length > 0) {
		getCheckProduct()
	}
	getList()
})

const indexMethod = (index: number) => {
	return searchParam.pageSize * (searchParam?.page - 1) + index + 1
}

// 获取产品列表
const getList = async () => {
	nextTick(() => {
		const params = {
			filters: getFilters(),
		}
		tableRef.value.getTableList({ params })
	})
}

// 获取已勾选的产品
const getCheckProduct = async () => {
	const res = await getProductByIds({
		ids: props.rowIds,
		status: 1,
	})
	if (res && res.code === 0) {
		checkList.value = res.data?.list
	}
}

// 获取查询参数
const getFilters = () => {
	// 产品用到
	if (props.addPolicyPromotionType == 2) {
		//纯水
		searchParam.category = [2]
	}
	if (props.addPolicyPromotionType == 3) {
		//矿·水
		searchParam.category = [1]
	}
	const list = [
		{
			fieldName: 'status',
			fieldValues: ['1'],
			fromType: 'select',
			operator: '=',
		},
	]
	if (searchParam.code) {
		list.push({
			fieldName: 'code',
			fieldValues: [searchParam.code],
			fromType: 'text',
			operator: 'LIKE',
		})
	}
	if (searchParam.name) {
		list.push({
			fieldName: 'name',
			fieldValues: [searchParam.name],
			fromType: 'text',
			operator: 'LIKE',
		})
	}
	if (searchParam.specification) {
		list.push({
			fieldName: 'specification',
			fieldValues: [searchParam.specification],
			fromType: 'text',
			operator: 'LIKE',
		})
	}
	if (searchParam.brand.length > 0) {
		list.push({
			fieldName: 'brand',
			fieldValues: searchParam.brand?.map((o) => String(o)),
			fromType: 'select',
			operator: 'in',
		})
	}
	if (searchParam.category.length > 0) {
		list.push({
			fieldName: 'category',
			fieldValues: searchParam.category?.map((o) => String(o)),
			fromType: 'select',
			operator: 'in',
		})
	}
	// 如果是预算汇总添加产品(只能添加箱的)
	if (props.addPolicyPromotionType == 4) {
		list.push({
			fieldName: 'unit',
			fieldValues: ['2'],
			fromType: 'select',
			operator: 'NOT IN',
		})
	}
	// 根据id查询
	if (props.addPolicyPromotionType == 5) {
		list.push({
			fieldName: 'id',
			fieldValues: props.ids?.map((id) => String(id)),
			fromType: 'select',
			operator: 'in',
		})
	}
	return list
}

// 重置
const resetData = () => {
	const params = new InitSearchParam()
	for (const key in searchParam) {
		if (key !== 'pageSize') {
			searchParam[key] = props.defaultParams?.[key] ?? params[key]
		}
	}
	getList()
}

// 分页
const handleCurrentChange = () => {
	tableRef.value.getTableList({
		pageable: { page: 1 },
		params: { filters: getFilters() },
	})
}

// 全选
const selectAll = (val) => {
	if (val?.length > checkList.value?.length) {
		checkList.value = uniqBy(concat(checkList.value, val), 'ID')
	} else {
		const data = tableRef.value?.element?.XZLTable?.data
		const ids: number[] = data?.map((item) => item.ID)
		checkList.value = checkList.value.filter((item) => !ids.includes(item.ID))
	}
}

// 勾选
const selectionChange = (val, row) => {
	const isChecked: boolean = val.findIndex((item) => item.ID === row.ID) > -1
	if (isChecked) {
		checkList.value.push(row)
	} else {
		checkList.value = checkList.value.filter((item) => item.ID !== row.ID)
	}
}

// 取消
const handleClose = () => {
	emit('update:modelValue', false)
}

// 确认
const handleConfirm = () => {
	const list = uniqBy(concat(tableRef.value.selectionList, checkList.value), 'ID')
	if (list.length === 0) {
		ElMessage.warning('请选择产品')
		return
	}
	emit('confirm', list)
	handleClose()
}
</script>

