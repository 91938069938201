import service from '@/utils/request'

//获取费用科目列表
export const getBudgetTableList = (data: any) => {
	return service({
		url: '/expenseBudget/getExpenseBudgetList',
		method: 'post',
		data,
	})
}
// (获取所有预算表接口)
export const getAllBudgetTable = (data: any) => {
	return service({
		url: '/expenseBudget/getAllExpenseBudgetListV2',
		method: 'post',
		data,
	})
}

// 费用管理-费用科目(启动接口)

export const enableUse = (data: any) => {
	return service({
		url: '/expenseBudget/enable',
		method: 'post',
		data,
	})
}

// 费用管理-费用科目(禁用接口)

export const disableUse = (data: any) => {
	return service({
		url: '/expenseBudget/disable',
		method: 'post',
		data,
	})
}

// 费用管理-费用科目(删除接口)

export const deleteBudgetTable = (data: any) => {
	return service({
		url: '/expenseBudget/deleteExpenseBudgetByIds',
		method: 'DELETE',
		data,
	})
}

//费用管理-费用科目(新增)

export const createBudgetTable = (data: any) => {
	return service({
		url: '/expenseBudget/createExpenseBudget',
		method: 'post',
		data,
	})
}

// 费用管理-费用科目(编辑)

export const updateBudgetTable = (data: any) => {
	return service({
		url: '/expenseBudget/updateExpenseBudget',
		method: 'post',
		data,
	})
}

//(获取所有启用科目列表)

export const getPreviousFeeSubjects = (data: any) => {
	return service({
		url: '/expenseAccount/getAllParentAccount',
		method: 'post',
		data,
	})
}

//费用管理-费用科目(获取所有启用维度列表)

export const getPreviousFeeDimensions = (data: any) => {
	return service({
		url: '/expenseCategory/getAllCategory',
		method: 'post',
		data,
	})
}

// 费用管理-预算表(通过id查询当前数据详情)

export const getBudgetTableById = (data: any) => {
	return service({
		url: '/expenseBudget/findExpenseBudget',
		method: 'post',
		data,
	})
}
// 费用管理-预算表动态生成编号

export const getSystenBudgetCode = (data: any) => {
	return service({
		url: '/expenseBudget/generateCode',
		method: 'get',
		data,
	})
}
