<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ListProTable',
})
</script>
<template>
	<el-card v-if="tableColumns.length === 0" class="h-full" body-class="h-full">
		<empty-state class="h-full" type="list" description="暂无权限，请联系管理员配置" />
	</el-card>
	<div v-else class="table-box">
		<!-- SearchForm -->
		<el-card v-if="showSearchForm" class="table-search">
			<SearchForm
				ref="searchFormRef"
				v-model="pageConfig"
				:searchField="tableState?.searchField ?? []"
				:pageId="pageId"
				:isReady="pageReady && tableReady"
				:searchParam="searchParamData"
				@filter-confirm="handleFilterSearch"
				@save-config="savePageConfig"
			/>
		</el-card>

		<el-card class="flex-1" body-class="flex flex-col h-full gap-lg">
			<!-- Table Buttons -->
			<div
				class="table-header"
				v-if="showColSetting || buttonList?.length"
				:class="[buttonList ? 'justify-between' : 'justify-end']"
			>
				<div class="flex-1">
					<slot name="buttons"></slot>
					<Buttons
						v-if="buttonList"
						:buttonList="buttonList"
						:row-key="rowKey"
						:exposeMap="exposeMap"
						:state="tableState"
					/>
				</div>
				<div class="inline-flex items-center gap-x-3" :class="{ 'ml-6xl': showColSetting || $slots.buttonHeader }">
					<slot name="buttonHeader"></slot>

					<ColSetting
						v-if="showColSetting"
						v-model="pageConfig"
						:columns="tableColumns"
						:pageSizes="pageSizes"
						@column-apply="columnApply"
					/>
				</div>
			</div>

			<!-- Table Header -->
			<slot name="tableHeader"> </slot>

			<!-- Table Content -->
			<div class="table-content">
				<XZLTable
					v-if="isShowColumn"
					ref="tableRef"
					v-loading="tableLoading"
					height="100%"
					:border="true"
					:rowKey="rowKey"
					:data="tableData"
					:columns="tableColumns"
					@selection-change="handleSelectionChange"
					@current-change="rowChange"
					v-bind="tableAttrs"
				>
					<template v-for="name in Object.keys(tableSlots)" #[name]="scope">
						<slot :name="name" v-bind="scope || {}"></slot>
					</template>
				</XZLTable>
				<div v-else class="h-full border border-solid border-line-1">
					<empty-state class="h-full" type="list" description="请勾选需要显示的列" />
				</div>
			</div>

			<div
				class="flex items-center text-gray-500"
				:class="[showPagination && (checkNumber > 0 || selectionList.length > 0) ? 'justify-between' : 'justify-end']"
			>
				<span v-if="checkNumber > 0 || selectionList.length > 0">已选{{ checkNumber || selectionList.length }}条</span>

				<!-- Pagination -->
				<xzl-pagination
					v-if="showPagination"
					class="!mt-0"
					:current-page="pageable.page"
					:page-size="pageable.pageSize"
					:pageSizes="pageSizes"
					:total="total"
					:size-change="sizeChange"
					:current-change="currentChange"
				/>
			</div>
		</el-card>
	</div>
</template>

<script setup lang="ts" name="ListProTable">
import { ref, computed, watch, useAttrs, useSlots } from 'vue'
import { ElMessage } from 'element-plus'
import { isEqual, isEmpty, slice, omit, toUpper, reverse, cloneDeep, pick, concat } from 'lodash-es'

import SearchForm from '@/components/proTable/components/SearchForm/index.vue'
import XZLTable from '@/components/proxyComponents/XZLTable/index.vue'
import Buttons from './components/Buttons/index.vue'
import ColSetting from './components/ColSetting/index.vue'
import EmptyState from '@/components/proxyComponents/XZLEmptyState/index.vue'

import { usePageConfig } from '../common/hooks/usePageConfig/index'
import { useTable } from '../common/hooks/useTable/index'
import { useUserStore } from '@/pinia/modules/user'

import { UsePageConfigOptions, PageConfigSetting, PageConfig } from '../common/hooks/usePageConfig/types'
import { UseTableOptions, ReqTableList } from '../common/hooks/useTable/types'
import { TablePropType, TableColumnProps } from '@/components/proxyComponents/XZLTable/types'
import { DialogDataType } from '@/hooks/usePageDrawer/types'

interface Props extends Pick<TablePropType, 'rowKey' | 'columns' | 'data'> {
	// 记忆配置接口配置
	configRequest?: Omit<UsePageConfigOptions, 'columns'>

	// 表格接口配置
	request?: {
		api: (data: any) => any
		params?: Omit<UseTableOptions, 'pageSize'>
	}

	// 筛选确认事件，返回正常的筛选参数
	filterConfirm?: () => ReqTableList

	// api返回的数据中，属于表格data的键名(key)，默认为list。如果api返回的是数组，则此属性应为空
	dataKey?: string

	// 是否显示筛选，默认true
	showSearchForm?: boolean

	// 是否显示列设置，默认true
	showColSetting?: boolean

	// 是否需要分页，默认true
	showPagination?: boolean

	// 分页参数
	pageSizes?: number[]

	// 已选择的数量，非必传。如果开启分页勾选，则需要传入此参数，因为表格中实时勾选的数量显示不对
	checkNumber?: number

	// 按钮列表配置
	buttonList?: DialogDataType[]
}

defineOptions({
	inheritAttrs: false,
})

const props = withDefaults(defineProps<Props>(), {
	rowKey: 'ID',
	dataKey: 'list',
	showSearchForm: true,
	showColSetting: true,
	showPagination: true,
	pageSizes: () => [20, 50, 100],
})
const emit = defineEmits(['update:columns', 'config-success'])

const attrs = useAttrs()
const slots = useSlots()

// 根据当前模块获取当前模块下的字段权限配置
const userStore = useUserStore()
const pageRouterName = toUpper(sessionStorage.getItem('activeRouter'))
const fieldList = userStore.userPermissions?.find((item) => toUpper(item?.name) == pageRouterName)?.fieldList || []

// 排除其他插槽，只需要表格的插槽
const tableSlots = omit(slots, ['tableHeader', 'buttonHeader', 'buttons'])
// 排除data（需要手动处理data）
const tableAttrs = omit(attrs, 'data')

const tableRef = ref<InstanceType<typeof XZLTable>>()
const tableColumns = ref<TableColumnProps[]>(props.columns)

// 监听columns变化，从外部改变columns时，内部也需要同步
watch(
	() => props.columns,
	(newVal) => {
		// 如果一个没有一个字段权限，则动态字段也同样不显示
		if (pageConfig.value.settings?.length === 0 && fieldList.length > 0) {
			tableColumns.value = []
			return
		}

		if (!isEqual(newVal, tableColumns.value)) {
			tableColumns.value = newVal
		}
	},
	{ deep: true }
)

// 从缓存中获取搜索参数
const searchParamData = computed(() => {
	if (pageId.value) {
		const pages = JSON.parse(sessionStorage.getItem('pages') as string) || []
		const pageValue = pages.find((item: any) => item.pageId === pageId.value)?.value
		return pageValue ? { filters: pageValue } : props.request?.params?.searchParam ?? {}
	}
	return {}
})

const { pageId, pageReady, pageConfig, fetchPageConfig, savePageConfig } = usePageConfig({
	...props.configRequest,
	immediate: true,
	columns: props.columns,
	onSuccess: async (params: ReqTableList) => {
		pageable.value.pageSize = pageConfig.value.pageSize

		// 首次加载，更新默认列
		// if (!props.showColSetting) {
		// 	pageConfig.value.settings = props.columns
		// 		?.filter((o) => !o.type && !o.isDyField)
		// 		.map((item) => pick(item, ['label', 'prop', 'fixed', 'isShow', 'isLock', 'isHideAuth']))
		// }

		// 更新列配置
		updateColumns(pageConfig.value.settings)

		// 如果是手动重新加载配置(fetchPageConfig方法), 或者列表接口默认不请求, 则获取列表的方法通过外部控制
		// 有时候可能只想刷新记忆配置，不一定刷新列表
		if (props.configRequest?.immediate === false || props.request?.params?.immediate === false) {
			emit('config-success', params)
			return
		}
		// 如果有前置回调(beforeCallback)有返回筛选参数, 获取模块配置成功后, 需要手动传入修改请求参数
		if (props.request) {
			getTableList(params)
		}
	},
})

const {
	tableLoading,
	tableReady,
	pageable,
	searchParam,
	totalParam,
	tableState,
	selectionList,
	getTableList,
	handlePageChange,
	handleSizeChange,
	handleSelectionChange,
	handleCurrentChange,
} = useTable(props.request?.api, {
	tableType: 'listProTable',
	...props.request?.params,
	immediate:
		typeof props.configRequest?.beforeCallback === 'function' || (props.configRequest?.isNeedConfig ?? true)
			? false
			: !!props.request?.params?.immediate,
	searchParam: {
		...searchParamData.value,
		filters: searchParamData.value?.filters?.map((item) => ({
			fieldName: item.fieldName,
			fieldValues: item.fieldValues?.map((value) => {
				return value || value === 0 ? String(value) : ''
			}),
			fromType: item.fromType,
			operator: item.operator,
			selectType: item.selectType,
		})),
	},
})

// 表格数据
const tableData = computed(() => {
	const data = props.data
	// 如果data从外部传入，则前端手动分页
	if (data?.length > 0) {
		// 不需要分页
		if (!props.showPagination) {
			return data
		}

		// 手动分页
		const page = pageable.value.page as number
		const pageSize = pageable.value.pageSize as number
		return slice(data, (page - 1) * pageSize, page * pageSize)
	}

	// 使用api接口数据
	return props.dataKey ? tableState.value?.[props.dataKey] : tableState.value
})

// 总数
const total = computed<number>(() => {
	const data = props.data
	return tableState.value?.total ?? data?.length
})

// 表格列是否全部为勾选显示
const isShowColumn = computed(() => {
	return tableColumns.value?.some((item) => item.isShow)
})

// 根据字段设置以及字段权限，重新生成表格列
const updateColumns = (settings: PageConfigSetting[]) => {
	// 有些列不需要权限，有些模块也不需要字段权限
	const isHideAuthColumn = props.columns.some((item) => item.isHideAuth)
	if (isEmpty(settings) && !isHideAuthColumn && fieldList.length > 0) {
		tableColumns.value = []
		emit('update:columns', tableColumns.value)
		return
	}

	// 类型列，统一放最前
	const typeColumns = tableColumns.value?.filter((item) => item.type)
	// 字段列，自定义排序
	const fieldColumns = tableColumns.value?.filter((item) => !item.type)

	// 从记忆配置中读取【字段顺序、是否显示、固定在左侧】属性
	const list = settings?.reduce((arr: TableColumnProps[], item: TableColumnProps) => {
		const target = fieldColumns.find((column) => item.prop === column.prop)
		if (target) {
			arr.push({ ...target, isShow: item.isShow, isLock: item.isLock })
		}
		return arr
	}, [])

	// 有新字段或权限被放开的字段，在记忆配置中不存在，则需要再次对比
	fieldColumns.forEach((column) => {
		if (!column.type) {
			// 是否存在于记忆配置中或list中
			const isConfig: boolean = list.findIndex((item) => item.prop === column.prop) !== -1

			// 是否有字段权限配置：如果该字段存在权限配置，则status === 1则代表有权限
			const fieldItem = fieldList.find((item) => item.name === column.prop)
			const isAuth: boolean = fieldItem?.status === 1

			// 有权限并且不在记忆配置中，可能是将该字段权限放开
			// 动态字段不需要权限
			// 排序方式，从默认列表中找到上一个字段位置并追加在其后面
			if (!isConfig && (isAuth || column.isDyField || column.isHideAuth)) {
				// 根据当前字段，以及默认列，找出上一个有权限的字段
				const prevIndex = getPrevIndex(fieldColumns, list, column)
				if (prevIndex >= 0) {
					list.splice(prevIndex + 1, 0, column)
				} else {
					list.unshift(column)
				}
			}
		}
	})

	tableColumns.value = typeColumns.concat(list)
	emit('update:columns', tableColumns.value)
}

/**
 * 根据当前字段获取索引
 * @param {Array} defaultColumns 默认列
 * @param {Array} settingColumns 记忆配置列
 * @param {Object} column 当前字段
 * @returns {number} 索引
 *
 */
const getPrevIndex = (defaultColumns, settingColumns, column) => {
	const _newColumns = reverse(cloneDeep(defaultColumns))
	const index = _newColumns.findIndex((o) => o.prop === column.prop)
	const prevItem = _newColumns.filter((el, inx) => {
		return settingColumns.findIndex((o) => o.prop === el.prop) >= 0 && inx > index
	})[0]
	return settingColumns.findIndex((o) => o.prop === prevItem?.prop)
}

// 筛选
const handleFilterSearch = async (value: any, isChange: boolean): Promise<void> => {
	if (isChange) {
		savePageConfig()
	}

	// 外部传入的筛选方法，可能有返回的筛选参数
	const data = (await props.filterConfirm) && props.filterConfirm()
	const filters = concat(value ?? [], data?.params?.filters ?? [])
	const params = {
		...data?.params,
		filters: isEmpty(filters) ? undefined : filters,
	}
	const pageable = {
		page: 1,
		...data?.pageable,
	}
	getTableList(
		{
			params,
			pageable,
		},
		{
			isHandleClickFilter: true,
		}
	)
}

// 应用列表字段设置
const columnApply = async (data: PageConfig, isRefresh: boolean): Promise<void> => {
	updateColumns(data.settings)
	const success: boolean = await savePageConfig()
	if (success) {
		ElMessage.success('应用成功')
		if (isRefresh) {
			getTableList({
				pageable: { pageSize: data.pageSize },
			})
		}
	}
}

// 分页
const currentChange = async (val: number) => {
	await handlePageChange(val)
	tableRef.value!.XZLTable.setScrollTop(0)
}

// 分页
const sizeChange = async (val: number) => {
	pageConfig.value.pageSize = val
	savePageConfig()
	await handleSizeChange(val)
	tableRef.value!.XZLTable.setScrollTop(0)
}

// 单选
const rowChange = (row: any, oldRow: any) => {
	// 没有单选图标，则不选中当前行
	const typeColumn = tableColumns.value.find((item) => item.type === 'radio')
	if (!typeColumn) {
		return
	}
	// 选中行单选被禁用
	if (typeof typeColumn?.selectable === 'function') {
		const selectable = typeColumn.selectable(row, null)
		if (!selectable && !!oldRow) {
			return
		}
	}
	handleCurrentChange(row)
}

const exposeMap = {
	tableState,
	element: tableRef,
	getTableList,
	selectionList,
	searchParam,
	totalParam,
	fetchPageConfig,
	savePageConfig,
}

defineExpose(exposeMap)
</script>

<style lang="scss" scoped>
.table-box {
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	gap: 12px;

	.table-search {
		position: relative;
	}

	.table-header {
		display: flex;
		align-items: center;
	}

	.table-content {
		flex: 1;
		height: 0;
	}
}
</style>

