<template>
	<inputBox
		ref="boxRef"
		:checkList="checkList"
		:treeProps="treeProps"
		:active="showPopover"
		:collapseTags="collapseTags"
		:placeholder="placeholder"
		:disabled="disabled"
		@remove="removeTag"
		@removeAll="removeAll"
		@click="handleOpen"
	/>

	<el-popover
		:virtual-ref="boxRef"
		virtual-triggering
		placement="bottom"
		:popper-style="{ width: boxWidth < 320 ? '320px' : boxWidth + 'px' }"
		trigger="click"
		:disabled="disabled"
		@show="handleOpen"
		@hide="handleClose"
	>
		<div v-if="loading.init" v-loading="loading.init" style="height: 280px"></div>
		<template v-else>
			<div v-if="treeData.length > 0">
				<XZLInput ref="inputRef" class="mb-3" v-model="keyword" @input="onQueryChanged" placeholder="可输入名称搜索" />
				<el-tree-v2
					ref="treeRef"
					v-loading="loading.init"
					v-bind="$attrs"
					:data="treeData"
					:props="treeProps"
					:filter-method="filterMethod"
					:show-checkbox="true"
					:height="280"
					@check-change="handleNodeCheck"
				>
					<template #default="{ data }">
						<el-tooltip class="box-item" effect="dark" :content="data?.[treeProps?.label]" placement="top">
							<span class="el-tree-node__label">{{ data?.[treeProps?.label] }}</span>
						</el-tooltip>
					</template>
				</el-tree-v2>
			</div>
			<EmptyState v-else />
		</template>
	</el-popover>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, nextTick, useAttrs } from 'vue'
import { ElTreeV2 } from 'element-plus'
import { useElementSize } from '@vueuse/core'
import { isEqual } from 'lodash-es'
import { useTrigger } from '@/hooks/useTrigger'
import { OpeationBtnType } from '@/hooks/useOperationBtnList'
import { getSaleAreaByMasterId } from '@/api/serveApi/basicInformation/salesArea'

import inputBox from './inputBox.vue'
import XZLInput from '@/components/proxyComponents/XZLInput/index.vue'
import EmptyState from '@/components/proxyComponents/XZLEmptyState/index.vue'

interface Props {
	// 绑定的部门id数组
	modelValue: string[]

	// 部门树props
	treeProps?: any

	// 多选时是否将选中值按文字的形式展示，默认 false
	collapseTags?: boolean

	// 类型：一般为新增或者编辑
	type?: OpeationBtnType

	// 是否禁用，默认false
	disabled?: boolean

	// 占位符提示文字
	placeholder?: string
}

const props = withDefaults(defineProps<Props>(), {
	treeProps: {
		label: 'areaName',
		value: 'ID',
		children: 'children',
		disabled: 'disabled',
	},
	placeholder: '请选择销售区域',
})
const emit = defineEmits(['update:modelValue'])

const attrs = useAttrs()

const loading = reactive<Record<string, boolean>>({
	init: false,
})
const boxRef = ref<HTMLElement | null>(null)
const treeRef = ref<InstanceType<typeof ElTreeV2>>()
const inputRef = ref<InstanceType<typeof XZLInput>>()
const treeData = ref([])
const keyword = ref<string>('') // 搜索关键字
const checkList = ref([]) // 选中的部门列表
const showPopover = ref<boolean>(false) // popover是否显示

const { emitTrigger } = useTrigger()
const { width: boxWidth } = useElementSize(boxRef)

watch(
	[() => props.modelValue, () => treeData.value],
	() => {
		if (props.modelValue?.length > 0 && treeData.value?.length > 0) {
			const ids = checkList.value?.map((item) => item?.[props.treeProps?.value])
			if (isEqual(ids, props.modelValue)) {
				return
			}
			nextTick(() => {
				treeRef.value?.setCheckedKeys(props.modelValue)
				handleNodeCheck()
			})
		} else if (props.modelValue?.length === 0) {
			nextTick(() => {
				treeRef.value?.setCheckedKeys([])
				checkList.value = []
			})
		}

		emitTrigger()
	},
	{
		deep: true,
		flush: 'post',
	}
)

onMounted(() => {
	getTreeData()
})

// 获取部门数据
const getTreeData = async () => {
	loading.init = true
	const res = await getSaleAreaByMasterId({ ids: [] })
	if (res?.code === 0) {
		treeData.value = res.data
	}
	loading.init = false
}

const handleOpen = () => {
	showPopover.value = true
	inputRef.value?.XZLInput?.focus()
}

const handleClose = () => {
	showPopover.value = false
}

// 输入框事件
const onQueryChanged = (query: string) => {
	treeRef.value!.filter(query)
}

// 筛选
const filterMethod = (query: string, node: any) => {
	return node?.[props.treeProps?.label]!.includes(query)
}

// 删除
const removeTag = (index: number) => {
	if (index < 0) return
	const item = checkList.value.splice(index, 1)[0]
	treeRef.value.setChecked(item?.[props.treeProps?.value], false)
	emit(
		'update:modelValue',
		checkList.value.map((o) => o?.[props.treeProps?.value])
	)
}

// 删除全部
const removeAll = () => {
	treeRef.value.setCheckedKeys([])
	checkList.value = []
	emit('update:modelValue', [])
}

// 勾选树节点
const handleNodeCheck = () => {
	const allNodes = treeRef.value.getCheckedNodes() // 当前选中的所有节点node
	const allKeys = treeRef.value.getCheckedKeys() // 当前选中的所有节点key

	if (attrs.checkStrictly) {
		checkList.value = allNodes
	} else {
		checkList.value = []
		if (allNodes.length > 0) {
			allNodes.forEach((item) => {
				if (!allKeys.includes(item.parentId)) {
					checkList.value.push(item)
				}
			})
		} else {
			checkList.value = []
		}
	}
	emit(
		'update:modelValue',
		checkList.value.map((o) => o?.[props.treeProps?.value])
	)
}
</script>

<style setup lang="scss">
.el-tree-node__content {
	display: flex;

	.el-tree-node__label {
		flex: 1;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}
}

.tag-box {
	max-height: 90px;
	overflow: auto;

	.el-tag {
		max-width: 95%;
		border-width: 0;

		.el-tag__content {
			max-width: 90%;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}
}

.input-placeholder {
	color: var(--el-input-placeholder-color);
}
</style>

