import { ElMessage } from 'element-plus'
import { computed, ref, watch, reactive, markRaw, defineAsyncComponent } from 'vue'
import { useOperationBtnList, OpeationBtnType, ModalWidthType } from '@/hooks/useOperationBtnList'
import {
	deleteCancellationApprove,
	exportCancellationEcord,
	exportFactoryCancellationSummary,
} from '@/api/serveApi/feeManagement/feeCancellationApprove'
import { DrawerWidthType, usePageDrawer } from '@/hooks/usePageDrawer/index'
import { ApplyType, DrawerType } from '@/hooks/usePageDrawer/types'
const ApplicationOddApproveDetail = defineAsyncComponent(
	() => import('../cancellationByApplicationOdd/components/approveCancellationByApplicationOdd.vue')
)
// 新的 自定义核销
const verificationApproval = defineAsyncComponent(
	() => import('@/view/feeManagement/feeCancellation/customVerification/components/verificationApproval.vue')
)
// 新的 按活动类型核销
const approverAddEdit = defineAsyncComponent(
	() => import('@/view/feeManagement/feeCancellation/cancellationByActivity/components/approverAddEdit/index.vue')
)
// 新的 政策核销
const initiateApproval = defineAsyncComponent(
	() => import('@/view/feeManagement/feeCancellation/writeOffByPolicy/components/initiateApproval.vue')
)

/**
 * @description
 * @param useComponent 1 按费用 2 按活动 3 按促销 4 自定义
 */
interface FormDataType {
	useComponent: number
	tabValue: number
}
export const formData = reactive<FormDataType>({
	useComponent: 1,
	tabValue: 1,
})
let selectList = ref([])
const ids = ref([])
const cancelType = computed(() => {
	return selectList.value?.map((item) => item.cancelType)
})
const useComponent = () => {
	let keys = null
	if (formData.useComponent) {
		keys = formData.useComponent
	} else {
		keys = cancelType.value[0]
	}
	switch (keys) {
		case 1:
			return markRaw(ApplicationOddApproveDetail)
		case 2:
			return markRaw(approverAddEdit)
		case 3:
			return markRaw(initiateApproval)
		case 4:
			return markRaw(verificationApproval)
	}
}

export const useCancellationApproval = (proTable) => {
	// 过滤出符合当前页面的按钮
	const { btnList, ApprovalStatusValid } = useOperationBtnList(DrawerType.FEECANCELLATIONAPPROVE)
	const btnListFilter = computed(() => {
		return [
			...btnList.filter((item) =>
				[
					OpeationBtnType.EXPORTCANCELLATIONRECORD,
					OpeationBtnType.EXPORTFACTORYCANCELLATIONSUMMARY,
					OpeationBtnType.EDIT,
					OpeationBtnType.DELETE,
					OpeationBtnType.CONFIRMVERIFICATION,
				].includes(item.type)
			),
		]
	})

	const initelectList = async (Ids) => {
		selectList.value = [...Ids]
	}
	const approveStatus = new Map([
		[1, '待提交'],
		[2, '待审批'],
		[3, '审批中'],
		[5, '已驳回'],
		[4, '已通过'],
	])

	/**多选校验 */
	const Multiple = (length: number = 0, label: string): Boolean => {
		if (length === 0) {
			ElMessage.warning(`请勾选需要${label}的数据!`)
			return false
		}
	}

	watch(
		() => selectList.value,
		(val) => {
			if (!val) return
			ids.value = val?.map((item) => item.ID)
			console.log(ids.value, 36)
		},
		{
			deep: true,
			immediate: true,
		}
	)
	// 表格按钮内容
	const dialogConfig = computed(() => {
		return {
			[OpeationBtnType.EXPORTCANCELLATIONRECORD]: {
				props: {
					exportParams: {
						ids: ids.value,
						cancelType: cancelType.value?.[0],
					},
				},
				preOpenValidation: () => {
					const valid = Multiple(ids.value?.length, '导出核销记录')
					if (valid === false) throw new Error('未勾选数据')
					// 获取核销类型数组
					const uniqueCancelType = [...new Set(cancelType.value)]
					if (uniqueCancelType?.length > 1) {
						ElMessage.warning('仅支持相同核销方式导出，请重新选择')
						throw new Error('仅支持相同核销方式导出，请重新选择')
					}
				},
				onConfirm: exportCancellationEcord,
			},
			[OpeationBtnType.EXPORTFACTORYCANCELLATIONSUMMARY]: {
				props: {
					exportParams: {
						ids: ids.value,
						cancelType: cancelType.value?.[0],
					},
				},
				preOpenValidation: () => {
					const valid = Multiple(ids.value?.length, '导出工厂核销汇总')
					if (valid === false) throw new Error('未勾选数据')
					// 获取核销类型数组
					const uniqueCancelType = [...new Set(cancelType.value)]
					if (uniqueCancelType?.length > 1) {
						ElMessage.warning('仅支持相同核销方式导出，请重新选择')
						throw new Error('仅支持相同核销方式导出，请重新选择')
					}
				},
				onConfirm: exportFactoryCancellationSummary,
			},
			[OpeationBtnType.EDIT]: {
				title: '编辑',
				component: useComponent(),
				width: ModalWidthType.PERCENTAGE60,
				props: {
					type: OpeationBtnType.EDIT,
					rowId: markRaw(ids),
					approvalId: selectList.value[0]?.ID,
					promotionTypeId: selectList.value[0]?.promotionTypeId,
					activityStatus: true,
				},
				apply: [ApplyType.LIST, ApplyType.FLOW],
				preOpenValidation: () => {
					const validMsg = ApprovalStatusValid(selectList.value, {
						valid: [1, 5, 6],
					})
					if (typeof validMsg === 'string') {
						throw new Error(validMsg)
					}
				},
			},
			[OpeationBtnType.DELETE]: {
				content: '确认删除所选审批单?',
				showButton: true,
				onConfirm: async () => {
					const res = await deleteCancellationApprove({ ids: ids.value })
					if (res.code !== 0) throw new Error(res.msg)
				},
				preOpenValidation: () => {
					const validMsg = ApprovalStatusValid(selectList.value, {
						valid: [1, 5, 6],
					})
					if (typeof validMsg === 'string') {
						throw new Error(validMsg)
					}
				},
			},
			[OpeationBtnType.CONFIRMVERIFICATION]: {
				title: '修改核销金额',
				component: useComponent(),
				width: ModalWidthType.PERCENTAGE60,
				props: {
					type: OpeationBtnType.CONFIRMVERIFICATION,
					rowId: markRaw(ids),
					approvalId: selectList.value[0]?.ID,
					promotionTypeId: selectList.value[0]?.promotionTypeId,
				},
				preOpenValidation: () => {
					const validMsg = ApprovalStatusValid(selectList.value, {
						valid: [2, 3],
					})
					if (typeof validMsg === 'string') {
						throw new Error(validMsg)
					}
				},
			},
		}
	})

	const buttonList = computed(() =>
		btnListFilter.value?.map((item) => {
			return {
				...item,
				...dialogConfig.value?.[item.type],
			}
		})
	)
	console.log(buttonList, 'buttonList')
	/**
	 * 活动管理-查看-抽屉的基本配置
	 * @param viewDetail 组件名
	 * @param buttonList 按钮信息
	 */
	const feeCancellationApprovalDetailsConfig = {
		title: '默认标题',
		width: DrawerWidthType.PERCENTAGE60,
		buttonList: buttonList,
		component: useComponent(),
	}
	return {
		buttonList,
		initelectList,
		feeCancellationApprovalDetailsConfig,
		approveStatus,
	}
}
