<template>
	<!-- 弹窗(dialog) -->
	<template v-if="viewType === 'dialog'">
		<div v-if="$slots.reference" @click="handleOpen">
			<slot name="reference"></slot>
		</div>
		<inputBox
			v-else
			:userList="checkList"
			:active="showDialog"
			:collapseTags="collapseTags"
			:placeholder="placeholder"
			:disabled="disabled"
			@remove="removeUser"
			@removeAll="removeAll"
			@click="handleOpen"
		/>
		<el-dialog v-model="showDialog" :title="dialogTitle" width="840" append-to-body>
			<userBox
				v-if="showDialog"
				v-model="checkList"
				:isAuth="isAuth"
				:isIncludeDisabled="isIncludeDisabled"
				:isCheckDepart="isCheckDepart"
				:unSelectable="unSelectable"
				:uniqueUser="uniqueUser"
				:multiple="multiple"
				@conform="handleConfirm"
				@cancel="handleCancel"
			/>
		</el-dialog>
	</template>

	<!-- 弹出层(popover) -->
	<template v-else-if="viewType === 'popover'">
		<el-popover
			v-model:visible="showPopover"
			placement="bottom-start"
			width="auto"
			trigger="click"
			:popper-style="{ padding: '20px' }"
			:disabled="disabled"
		>
			<template #reference>
				<div v-if="$slots.reference" @click="handleOpen">
					<slot name="reference"></slot>
				</div>
				<inputBox
					v-else
					:userList="checkList"
					:active="showPopover"
					:collapseTags="collapseTags"
					:placeholder="placeholder"
					:disabled="disabled"
					@remove="removeUser"
					@removeAll="removeAll"
				/>
			</template>

			<userBox
				v-if="showPopover"
				v-model="checkList"
				:isAuth="isAuth"
				:isIncludeDisabled="isIncludeDisabled"
				:isCheckDepart="isCheckDepart"
				:unSelectable="unSelectable"
				:uniqueUser="uniqueUser"
				:multiple="multiple"
				@conform="handleConfirm"
				@cancel="handleCancel"
			/>
		</el-popover>
	</template>

	<!-- 面板(panel) -->
	<template v-else-if="viewType === 'panel'">
		<userBox
			v-model="checkList"
			:isAuth="isAuth"
			:isIncludeDisabled="isIncludeDisabled"
			:isCheckDepart="isCheckDepart"
			:unSelectable="unSelectable"
			:uniqueUser="uniqueUser"
			:multiple="multiple"
			@conform="handleConfirm"
			@cancel="handleCancel"
		/>
	</template>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { isEqual } from 'lodash-es'
import { useVModel } from '@vueuse/core'
import { concat, orderBy, indexOf } from 'lodash-es'
import { useTrigger } from '@/hooks/useTrigger'
import { ModelProps, ListItem, Category } from './types'
import inputBox from './inputBox.vue'
import userBox from './userBox.vue'
import { getUserInfoList } from '@/api/serveApi/basicInformation/personnelMgr'
import { getOrgDepartmentByDepartmentIds } from '@/api/serveApi/basicInformation/organizationMgr'

interface Props {
	// 绑定值，不能选择部门的情况下：类型为 number 或 number[]。可以选择部门，则类型为 Array<number | string> | number | string
	// 默认返回类型: 多选返回数组，单选返回number
	modelValue: ModelProps

	// 是否需要数据权限，默认 true
	isAuth?: boolean

	// 是否需要停用的数据，默认false
	isIncludeDisabled?: boolean

	// 是否可以勾选部门：只有筛选时可选择部门，默认false
	isCheckDepart?: boolean

	// 视图类型，默认 dialog
	viewType?: 'popover' | 'dialog' | 'panel'

	// 弹窗标题
	dialogTitle?: string

	// 不可选择的用户【userId】集合，默认为空
	// 默认该用户所有身份不可选择，可以通过【uniqueUser】属性控制是否可以选择用户其他身份
	unSelectable?: number[]

	// 与【unSelectable】配合使用，默认 true
	// 若【uniqueUser】为 true 时，则表示用户所有身份不可选择，反之则用户其他身份可以被选择
	uniqueUser?: boolean

	// 多选，默认 true
	multiple?: boolean

	// 多选时是否将选中值按文字的形式展示，默认 false
	collapseTags?: boolean

	// 单选的返回值类型，默认 number
	singleValueType?: 'number' | 'array'

	// 是否禁用，默认 false
	disabled?: boolean

	// 占位符
	placeholder?: string
}

const props = withDefaults(defineProps<Props>(), {
	unSelectable: () => [],
	uniqueUser: true,
	isAuth: true,
	isIncludeDisabled: false,
	isCheckDepart: false,
	viewType: 'dialog',
	multiple: true,
	collapseTags: false,
	singleValueType: 'number',
	disabled: false,
	dialogTitle: '选择人员',
	placeholder: '请选择',
})
const emit = defineEmits(['update:modelValue', 'change', 'confirm', 'cancel'])
const userData = useVModel(props, 'modelValue', emit)

const showDialog = ref<boolean>(false)
const showPopover = ref<boolean>(false)
const checkList = ref<ListItem[]>([])
const { emitTrigger } = useTrigger()

// 监听绑定值变化，如果有值则获取对应人员信息
watch(
	() => props.modelValue,
	() => {
		initUserList()
		emitTrigger()
	},
	{ deep: true }
)

onMounted(() => {
	initUserList()
})

// 初始化绑定数据(已有绑定值时)
const initUserList = () => {
	const values = props.modelValue
	const hasValue: boolean = ['number', 'string'].includes(typeof values)
		? !!values
		: Array.isArray(values) && values.length > 0
	if (hasValue) {
		const checkedIds = checkList.value?.filter((item) => item.type === Category.User).map((item) => item.userId)
		const checkDepartIds = checkList.value
			?.filter((item) => item.type === Category.Depart)
			.map((item) => item.departmentId)

		const value: Array<number | string> = Array.isArray(props.modelValue) ? props.modelValue : [props.modelValue]
		const userIDList: number[] = []
		const departIDList: string[] = []

		value.forEach((id) => {
			if (typeof id === 'number') {
				userIDList.push(id)
			} else {
				departIDList.push(id)
			}
		})
		if (
			(!isEqual(checkedIds, userIDList) && userIDList.length > 0) ||
			(!isEqual(checkDepartIds, departIDList) && departIDList.length > 0)
		) {
			getModelValueInfo(departIDList, userIDList)
		}
	} else {
		checkList.value = []
	}
}

const getModelValueInfo = async (departIds, userIds) => {
	const res = await Promise.all([getDepartList(departIds), getUserList(userIds)])
	const list = concat(res[0], res[1])
	const modelValue = props.modelValue

	// 按照原始顺序排序
	if (Array.isArray(modelValue)) {
		checkList.value = orderBy(
			list,
			[(item) => indexOf(props.modelValue, item.type === Category.User ? item.userId : item.departmentId)],
			['asc']
		)
	} else {
		checkList.value = list
	}
}

// 根据部门ID获取部门信息
const getDepartList = async (departmentIds: string[]): Promise<ListItem[]> => {
	if (departmentIds?.length === 0) {
		return []
	}
	const res = await getOrgDepartmentByDepartmentIds({
		departmentIds,
	})
	if (res && res.code === 0) {
		return res.data?.reorgDepartment?.map((item) => {
			return {
				type: Category.Depart,
				departmentId: item.departmentId,
				departmentName: item.name,
				employeeNum: item.employeeNum,
				status: item.status,
			}
		})
	}
	return []
}

// 根据用户ID获取用户信息
const getUserList = async (userIDList: number[]): Promise<ListItem[]> => {
	if (userIDList?.length === 0) {
		return []
	}
	const res = await getUserInfoList({
		userIDList,
	})
	if (res && res.code === 0) {
		return res.data?.map((item) => {
			return {
				type: Category.User,
				empId: item.empId,
				userId: item.userId,
				userName: item.name,
				departmentId: item.departmentId,
				departmentName: item.departmentName,
				departmentNameList: item.departmentNameList,
				jobPositionId: item.jobPositionId,
				jobPositionName: item.jobPositionName,
				headerImg: item.headerImg,
				status: item.status,
			}
		})
	}
	return []
}

// 删除用户
const removeUser = (index: number) => {
	checkList.value.splice(index, 1)
	handleConfirm()
}

// 删除全部
const removeAll = () => {
	checkList.value = []
	handleConfirm()
}

// 打开
const handleOpen = () => {
	if (props.disabled) return
	if (props.viewType === 'dialog') {
		showDialog.value = true
	}
}

// 关闭弹窗或popover
const handleClose = () => {
	switch (props.viewType) {
		case 'dialog':
			showDialog.value = false
			break
		case 'popover':
			showPopover.value = false
			break
		default:
	}
}

// 点击取消
const handleCancel = () => {
	switch (props.viewType) {
		case 'dialog':
			showDialog.value = false
			break
		case 'popover':
			showPopover.value = false
			break
		case 'panel':
			emit('cancel')
			break
		default:
	}
}

// 点击确定
const handleConfirm = () => {
	let updateValue: ModelProps = null
	// 多选默认返回数组类型，单选默认返回number类型，单选时也可以指定返回数组类型
	if (!props.multiple && props.singleValueType === 'number') {
		const checkData = checkList.value?.[0]
		if (checkData) {
			updateValue = checkData.type === Category.User ? checkData.userId : checkData.departmentId
		} else {
			updateValue = null
		}
	} else {
		updateValue = checkList.value?.map((item) => {
			return item.type === Category.User ? item.userId : item.departmentId
		})
	}
	userData.value = updateValue
	emit('change', updateValue)
	emit('confirm', getCheckedUser())
	handleClose()
}

// 获取已选中联系人列表
const getCheckedUser = (): ListItem[] => {
	return [...checkList.value]
	// return checkList.value.map((item: ListItem) => {
	// 	return pick(item, [
	// 		'empId',
	// 		'userId',
	// 		'userName',
	// 		'departmentId',
	// 		'departmentName',
	// 		'departmentNameList',
	// 		'jobPositionId',
	// 		'jobPositionName',
	// 		'headerImg',
	// 		'status',
	// 	])
	// })
}

defineExpose({
	getCheckedUser,
})
</script>

