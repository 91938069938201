<template>
	<div v-if="loading">
		<el-skeleton style="--el-skeleton-circle-size: 38px">
			<template #template>
				<div v-for="item in showCount" :key="item" class="content-box is-loading">
					<el-skeleton-item variant="circle" />
					<div class="flex-1" style="padding-left: 10px">
						<el-skeleton-item variant="text" style="margin-right: 16px" />
						<el-skeleton-item variant="text" style="width: 50%" />
					</div>
				</div>
			</template>
		</el-skeleton>
	</div>

	<template v-else>
		<div v-if="listData" v-bind="containerProps" :style="{ height: parseInt(itemHeight) * showCount + 'px' }">
			<div v-bind="wrapperProps" v-infinite-scroll="handleScrollLoad" :infinite-scroll-immediate="false">
				<el-checkbox-group v-if="list.length > 0" v-model="checkList" @change="changeUser">
					<template v-for="(item, index) in list" :key="item.data.departmentId">
						<div v-if="item.data.type === Category.Depart" class="relative" @mouseover="mouseOver(index)">
							<el-checkbox
								v-if="isCheckDepart"
								:label="item.data"
								class="content-box"
								:class="[currentIndex === index && 'active', !multiple && 'circle']"
							>
								<UserItem :item="item.data" />
							</el-checkbox>
							<div v-else class="content-box" :class="[currentIndex === index && 'active']" @click="addTab(item.data)">
								<UserItem :item="item.data" />
							</div>
							<el-icon
								class="absolute top-1/2 right-[10px] w-[22px] h-[22px] -translate-y-1/2 rounded-sm"
								:class="[
									getBelow(item.data)
										? 'cursor-pointer hover:text-primary hover:bg-primary hover:bg-opacity-10'
										: 'cursor-not-allowed text-gray-300',
								]"
								@click="handleGoBelow(item.data)"
							>
								<ArrowRight />
							</el-icon>
						</div>
						<el-checkbox
							v-else
							:label="item.data"
							class="content-box"
							:class="[currentIndex === index && 'active', !multiple && 'circle']"
							:key="item.data.userId"
							:disabled="getDisabled(item.data)"
							@mouseover="mouseOver(index)"
						>
							<UserItem :item="item.data" />
						</el-checkbox>
					</template>
				</el-checkbox-group>
				<empty-state v-else type="list" description="暂无数据" />

				<!-- 滚动加载状态 -->
				<div v-if="scrollLoading || noMore" class="h-8 flex items-center justify-center mt-[10px]">
					<template v-if="scrollLoading">
						<el-icon class="is-loading mr-2"> <Loading /> </el-icon>
						<span class="text-gray-400">正在加载中</span>
					</template>
					<span v-if="noMore">没有更多了</span>
				</div>
			</div>
		</div>

		<ul v-else class="h-full overflow-auto">
			<li v-for="(item, index) in checkList" :key="item.userId" class="content-box">
				<UserItem :item="item" closeable @removeUser="removeUser(index)" />
			</li>
		</ul>
	</template>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { concat } from 'lodash-es'
import { Loading, ArrowRight } from '@element-plus/icons-vue'
import { useVModel, useVirtualList } from '@vueuse/core'
import { ListItem, ListDataType, Category, DisabledUserType } from './types'

import UserItem from './userItem.vue'
import EmptyState from '@/components/proxyComponents/XZLEmptyState/index.vue'

interface Props {
	modelValue: any
	listData?: ListDataType
	loading?: boolean
	scrollLoading?: boolean
	noMore?: boolean
	disabledUser?: DisabledUserType
	uniqueUser?: boolean
	multiple?: boolean
	isCheckDepart?: boolean
	addTab?: (data: ListItem) => void
}

const props = defineProps<Props>()
const emit = defineEmits(['update:modelValue', 'scrollLoad'])
const checkList = useVModel(props, 'modelValue', emit)

// 可视数量
const showCount: number = 6
// item高度
const itemHeight: string = '54px'

const currentIndex = ref<number>(-1)

// 虚拟列表渲染数据
const dataList = computed(() => {
	if (props.listData) {
		return concat(props.listData.departList, props.listData.userList)
	}
	return []
})

watch(
	dataList,
	() => {
		console.log('dataList', dataList.value)
	},
	{ deep: true }
)

const { list, containerProps, wrapperProps } = useVirtualList(dataList, {
	itemHeight: parseInt(itemHeight),
})

// 鼠标移入
const mouseOver = (index) => {
	currentIndex.value = index
}

// 滚动加载
const handleScrollLoad = () => {
	emit('scrollLoad')
}

// 点击部门下级
const handleGoBelow = (item: ListItem) => {
	if (getBelow(item)) {
		props.addTab(item)
	}
}

// 部门是否可以点击向下
const getBelow = (item: ListItem): boolean => {
	if (checkList.value.length > 0) {
		return !checkList.value.some((el) => el.type === Category.Depart && el.departmentId === item.departmentId)
	}
	return true
}

// 用户是否可选择
const getDisabled = (item: ListItem) => {
	if (props.uniqueUser) {
		return props.disabledUser.empIds?.includes(item.empId)
	}
	return props.disabledUser.userIds?.includes(item.userId)
}

// 删除用户
const removeUser = (index: number): void => {
	checkList.value.splice(index, 1)
}

// 选择用户
const changeUser = (value) => {
	if (!props.multiple && value.length > 0) {
		checkList.value = [value[value.length - 1]]
	}
}
</script>

<style lang="scss" scoped>
.content-box {
	display: flex;
	align-items: center;
	width: 100%;
	height: v-bind(itemHeight);
	padding: 0 10px;
	border-radius: 6px;
	cursor: pointer;

	&.active,
	&:hover {
		background-color: #f5f5f5;
	}
}

.is-loading {
	&.content-box {
		&:hover {
			background-color: transparent;
		}
	}
}

.circle {
	:deep(.el-checkbox__inner) {
		border-radius: 50%;
		&::after {
			left: 5px;
		}
	}
}

.el-checkbox {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	padding-right: 10px;
	margin-right: 0;
	font-weight: normal;

	:deep(.el-checkbox__label) {
		flex: 1;
		display: flex;
		align-items: center;
		padding-left: 0;
		padding-left: 8px;
		overflow: hidden;
	}
}
</style>

