<template>
	<el-empty
		:image="$slots.image ? '' : image ?? currentType?.image"
		:image-size="imageSize ?? currentType?.imageSize"
		:description="description ?? currentType?.description"
	>
		<template v-for="(val, name) in $slots" #[name]="slotData">
			<slot :name="name" v-bind="slotData || {}"></slot>
		</template>
	</el-empty>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { EmptyType, EmptyTypeListType } from './types'
import noticeImage from '@/assets/images/empty/notice.png'
import messageImage from '@/assets/images/empty/message.png'
import notDataImage from '@/assets/images/empty/not-data.png'
import notInfoImage from '@/assets/images/empty/not-info.png'

interface Props {
	// 空状态类型(必须)，目前有四种：公告(notice)、消息(message)、列表(list)、内容详情(info)，默认为list
	type?: EmptyType

	// 参考element-plus Empty组件的image属性
	image?: string

	// 参考element-plus Empty组件的imageSize属性
	imageSize?: number

	// 参考element-plus Empty组件的description属性
	description?: string
}
const props = withDefaults(defineProps<Props>(), {
	type: 'list',
})

const typeList: EmptyTypeListType[] = [
	{ type: 'notice', image: noticeImage, imageSize: 126, description: '暂无公告' },
	{ type: 'message', image: messageImage, imageSize: 126, description: '暂无消息' },
	{ type: 'list', image: notDataImage, imageSize: 126, description: '暂无数据' },
	{ type: 'info', image: notInfoImage, imageSize: 126, description: '暂无信息' },
]

const currentType = computed(() => {
	return typeList.find((item) => item.type === props.type)
})
</script>

