<template>
	<el-input
		ref="inputRef"
		v-bind="$attrs"
		:model-value="internalValue"
		@input="handleInput"
		@blur="handleBlur"
		clearable
	>
		<template v-for="name in Object.keys($slots)" #[name]="scope">
			<slot :name="name" v-bind="scope || {}"></slot>
		</template>
	</el-input>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useVModel } from '@vueuse/core'

const props = defineProps({
	modelValue: {
		type: String,
		default: '',
	},
})
const emit = defineEmits(['blur', 'input', 'update:modelValue', 'change'])

const internalValue = useVModel(props, 'modelValue', emit)
const inputRef = ref()

const handleInput = (value) => {
	value = value.trimStart()
	internalValue.value = value
	emit('input', value)
	emit('change', value)
}

const handleBlur = (value) => {
	emit('blur', value)
	if (!internalValue.value) return
	internalValue.value = internalValue.value.trimEnd()
}

defineExpose({
	XZLInput: inputRef,
})
</script>
